<script setup lang="ts">
import {computed, defineOptions} from "vue";
import SchedulingOperationWrapper from "@/components/Scheduling/Operations/SchedulingOperationWrapper.vue";
import SchedulingOperationCard from "@/components/Scheduling/Operations/SchedulingOperationCard.vue";
import OperationPriorityChip from "@/components/Scheduling/Operations/OperationPriorityChip.vue";
import OperationStatus from "@/components/Scheduling/Operations/OperationStatus.vue";
import DelayChip from "@/components/Commons/DelayChip.vue";
import {CSS_OPERATION_CARD_SELECTED_CLASS, OF_STATUS} from "@/config/constants";
import {SchedulingOperation} from "@/interfaces";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  operation: SchedulingOperation;
  hasDoneOps?: boolean;
}>();

const emit = defineEmits<{
  (
    e: "change-status",
    payload: {
      update: Partial<SchedulingOperation>;
      operation: SchedulingOperation;
    },
  );
  (
    e: "change-priority",
    payload: {
      update: Partial<SchedulingOperation>;
      operation: SchedulingOperation;
    },
  );
}>();

const isOPDone = computed(() => props.operation.op_status === OF_STATUS.DONE);

function changePriority(new_priority: string) {
  const {operation} = props;
  const update = {fast_track: new_priority};
  emit("change-status", {update, operation});
}
</script>

<template>
  <v-tooltip location="top" content-class="gantt-row--cell-tooltip">
    <template v-slot:activator="{props}">
      <SchedulingOperationWrapper
        v-bind="{...props, ...$attrs}"
        :operation="operation"
        :has-done-ops="hasDoneOps"
        class="pa-1 pb-0"
      >
        <template
          v-slot:default="{
            colorCategoryClass,
            header,
            isSelected,
            operationDelay,
            operationQuantity,
            selectCard,
            showOperation,
          }"
        >
          <div
            :class="[
              {
                [CSS_OPERATION_CARD_SELECTED_CLASS]: isSelected,
                'scheduling-operation-row__op-status-done': isOPDone,
              },
              colorCategoryClass,
            ]"
            class="scheduling-operation-row__content"
            @click="selectCard"
          >
            <strong
              class="scheduling-operation-row__header text-ellipsis text-subtitle-2"
            >
              {{ header }}
            </strong>

            <span class="scheduling-operation-row__info__quantity">
              {{ operationQuantity.opQuantityTxt }}
            </span>

            <OperationPriorityChip
              v-if="operation.fast_track"
              :op="operation"
              @change-priority="changePriority"
              edit-only
            />

            <DelayChip :delay="operationDelay" />

            <OperationStatus
              :operation="operation"
              hide-chip-text
              @change-status="
                (update) => emit('change-status', {update, operation})
              "
            />

            <vue-feather
              class="scheduling-operation-row__maximize"
              type="maximize-2"
              size="16px"
              @click.stop="showOperation"
            />
          </div>
        </template>
      </SchedulingOperationWrapper>
    </template>

    <SchedulingOperationCard
      :operation="operation"
      :has-done-ops="hasDoneOps"
      compact
    />
  </v-tooltip>
</template>

<style scoped lang="scss">
.scheduling-operation-row__content {
  &.scheduling-operation-row__op-status-done {
    border-radius: 4px;
    background-color: rgb(var(--v-theme-newLightGrey));

    .scheduling-operation-row__header {
      color: rgb(var(--v-theme-newSubText));
    }

    & .scheduling-operation-row__info__quantity {
      color: rgb(var(--v-theme-newSubText));
    }
  }

  --x-padding: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 2px var(--x-padding);
  overflow: hidden; // solely added for colors categories

  &[class*="--colors-categories"] {
    --indicator-width: 4px;
    padding-left: calc(var(--x-padding) + var(--indicator-width));

    &::before {
      width: var(--indicator-width);
    }
  }

  & span,
  & strong {
    white-space: nowrap;
  }

  & span {
    // required for v-tooltip
    display: block;
  }

  &.selected-card {
    background-color: rgb(var(--v-theme-newHover));
    outline: 1px solid rgb(var(--v-theme-newPrimaryRegular));
    border-radius: 4px;
  }
}

.scheduling-operation-row__header {
  line-height: 1;
}

.scheduling-operation-row__info__quantity {
  margin-left: auto;
}

.scheduling-operation-row__maximize {
  cursor: pointer;
  flex: 0 0 16px;
}
</style>
