<template>
  <div :class="['gantt-table-header', {'is-list': isList}]">
    <template v-if="isList">
      <div class="gantt-table-header--entity-name-wrapper">
        <vue-feather
          :stroke="variables.newSelected"
          :type="areSomeSelected ? 'minus-square' : 'square'"
          class="cursor-pointer"
          size="20"
          @click="setGanttSelectedEntities"
        />
        <template v-if="areSomeSelected">
          <div>
            <span class="font-weight-normal">
              {{
                $t(
                  "scheduling.headers.gantt_selected_ofs",
                  ganttSelectedEntities.length,
                )
              }}
              :
            </span>

            {{ ganttSelectedEntities.length }}
          </div>

          <FDropdown
            placeholder="Actions"
            :items="selectedEntitiesActions"
            :outlined="['newLayerBackground']"
            btn-class="w-100px"
            item-value="type"
            medium
            @select="onActionSelect"
          />
        </template>
        <template v-else>
          {{ $t("scheduling.headers.gantt_of_number") }}
        </template>
      </div>

      <template v-if="!areSomeSelected">
        <div
          v-if="!getHiddenHeaders().includes('details')"
          class="gantt-table-header--entity-details-wrapper"
        >
          {{ $t("scheduling.headers.gantt_details") }}
        </div>

        <div
          v-if="!getHiddenHeaders().includes('quantite')"
          class="gantt-table-header--entity-quantite-wrapper"
        >
          {{ $t("scheduling.headers.gantt_quantite") }}
        </div>

        <div
          v-if="!getHiddenHeaders().includes('tags')"
          class="gantt-table-header--entity-tags-wrapper"
        >
          {{ $t("scheduling.headers.gantt_tags") }}
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {storeToRefs} from "pinia";
import {FDropdown} from "@/components/Global";
import type {GanttableEntity} from "@/interfaces";
import {useGanttStore} from "@/stores/ganttStore";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "gantt-table-header",
  components: {FDropdown},
  props: {
    isList: {type: Boolean, default: false},
    entities: {
      type: Array as PropType<GanttableEntity[]>,
      default: () => [] as GanttableEntity[],
    },
  },
  inject: ["getHiddenHeaders"],
  emits: ["update-status", "update-tags"],
  setup() {
    const {ganttSelectedEntities} = storeToRefs(useGanttStore());

    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);

    return {ganttSelectedEntities, variables};
  },
  computed: {
    // list of actions to apply on store: ganttSelectedEntities
    selectedEntitiesActions(): Array<{
      type: string;
      name: string;
      value: string;
    }> {
      // the `value` attribute is used to determine the logic on click inside this.onActionSelect
      return [
        {
          type: "text",
          name: this.$t("scheduling.lists.gantt_of_action_status"),
          value: "status",
        },
        {
          type: "text",
          name: this.$t("scheduling.lists.gantt_of_action_tags"),
          value: "tags",
        },
      ];
    },
    areSomeSelected(): boolean {
      return this.ganttSelectedEntities.length > 0;
    },
  },
  methods: {
    onActionSelect(action: {type: string; name: string; value: string}): void {
      switch (action.value) {
        case "status":
          return this.$emit("update-status");
        case "tags":
          return this.$emit("update-tags");
        default:
          return;
      }
    },
    setGanttSelectedEntities(): void {
      this.ganttSelectedEntities = this.areSomeSelected ? [] : this.entities;
    },
  },
});
</script>

<style lang="scss">
.gantt-table-header {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--gantt-prefix-width);
  height: var(--gantt-total-header-height);
  display: flex;
  background: rgb(var(--v-theme-newLayerBackground));
  z-index: 6;

  &:not(.is-list) {
    border-bottom: var(--gantt-border);
  }

  &::after {
    --border-container-height: 24px;
    //content: "";
    position: absolute;
    bottom: calc((var(--border-container-height) - 1px) * -1);
    left: 0;
    width: calc(100% + 1px);
    height: var(--border-container-height);
    border: var(--gantt-border);
    border-bottom: none;
    border-radius: 8px 0 0 0;
  }

  & > div {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    color: rgb(var(--v-theme-newSubText));
    font-weight: 600;
    font-size: 14px;

    &:not(:first-child) {
      border-left: var(--gantt-border);
    }
  }

  & .gantt-table-header--entity-name-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 0 0 calc(var(--gantt-entity-name-width) - 2px);

    & i {
      flex: var(--gantt-select-icon-flex);
    }

    &:has(span) {
      flex: 1;
    }
  }

  & .gantt-table-header--entity-details-wrapper {
    flex: 0 0 var(--gantt-entity-details-width);
  }

  & .gantt-table-header--entity-quantite-wrapper {
    flex: 0 0 var(--gantt-entity-quantite-width);
  }

  & .gantt-table-header--entity-tags-wrapper {
    flex: 0 0 var(--gantt-entity-tags-width);
  }
}
</style>
