import _ from "lodash";
import {
  recursiveBreadcrumb,
  recursiveGroupBy,
} from "@/components/Simulation/Stocks/helpers";
import {useClientsConfigurations} from "@/composables/useClientsConfigurations";
import {
  STOCKS_DEMANDS_FIRST_COLUMN_FIELDS,
  STOCKS_LOWEST_LEVEL_FIELD_NAME,
  STOCKS_VALUATION_FIELDS_PREFIX,
  STOCKS_VALUATION_WEIGHTED_FIELDS,
  STOCKS_TABLE_VIEWS,
} from "@/config/constants";
import {
  Demand,
  GenericConfigObject,
  GenericTableColumn,
  GroupedByObject,
  Product,
  StocksFilter,
  StocksGroupBy,
} from "@/interfaces";
import {i18n} from "@/i18n";
import {n} from "@/tscript/utils/generalHelpers";
import {
  getChildrenPrimaryKey,
  getFirstColumnTypeFromField,
} from "@/components/Simulation/Stocks/helpers";
import moment from "moment";
import {storeToRefs} from "pinia";
import {useStocksStore} from "@/stores/stocksStore";
import {useAvailableLoadQuantityFields} from "@/composables/load/useAvailableLoadQuantityFields";
import {computed} from "vue";
import {useMainStore} from "@/stores/mainStore";
import {useNavigation} from "@/composables/useNavigation";

export function useStocksUtils() {
  const {getClientConfig} = useClientsConfigurations();
  const {t} = i18n;
  const {getStocksBreadcrumbs, getStocksGroupBy, isSecondaryStocksUnit} =
    storeToRefs(useStocksStore());
  const {stations, simulation} = storeToRefs(useMainStore());

  const {
    currentStocksLoadQuantityField,
    getCurrentStocksLoadQuantityFieldUnit,
  } = useAvailableLoadQuantityFields();
  const {updateBreadcrumbs} = useNavigation();

  /**
   * @returns a detailed string representing the current week
   * @example S.04 30/01 - 06/02
   */
  const getDetailedWeekLabel = (date: unknown): string => {
    const d = date || undefined;
    const weekNumber = `${t("global.week_short")}.${moment(d).format("WW")}`;
    const start = moment(d).format("DD/MM");
    const end = moment(d).add(6, "days").format("DD/MM");
    return `${weekNumber}\n${start} - ${end}`;
  };

  // FIXME: make a single function with getDetailedWeekLabel/getDetailedWeekLabelFromPeriodObject
  const getDetailedWeekLabelFromPeriodObject = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    const weekNumber = `${t("global.week_short")}.${moment(startDate).format(
      "WW",
    )}`;

    const start = moment(startDate).format("DD/MM");
    const end = moment(endDate).format("DD/MM");

    return `${weekNumber}\n${start} - ${end}`;
  };

  /**
   * returns the item path which stores the value associated to the @field
   * since the `extra_infos` object is totally arbitrary, its keys may change
   * given the current environment
   *
   * NB: this is so far used only for the demands, and while invoking this method could
   * be avoided by defining the path directly in the headerColumns (StocksDemands) it has
   * been kept as such to isolate this logic and allow more flexibility later on
   */
  function getField(field: string, client_id: string) {
    if (!STOCKS_DEMANDS_FIRST_COLUMN_FIELDS.includes(field)) return field;

    /**
     * NB: we don't need the environment handling for the current usage,
     * but if it changes it should be added as a function parameter
     */
    const configKey = getClientConfig({
      client_id,
      key: `DEMAND_GROUP_BY_${field.toUpperCase()}_FIELD`,
    });

    return configKey ?? field;
  }

  /**
   * returns @demand 's value for the given @field
   * the @field passed is necessarily one within STOCKS_DEMAND_GROUP_BY_FIELDS
   * but doesn't represent necessarily an actual key within @demand , which is why this function exists
   */
  function getDemandFieldValue(
    demand: Demand,
    field: string,
    client_id: string,
    returnValueOnFalsy: string = null,
  ): string {
    const value = _.get(demand, getField(field, client_id));

    // we cast as string since some fields (e.g. the key storing the order number) have no defined type (exists as number/string)
    if (!returnValueOnFalsy || value) return `${value}`;

    return returnValueOnFalsy;
  }

  function groupDemands(
    demands: Demand[],
    groupByArray: GenericConfigObject[],
    breadcrumbs: GenericConfigObject[],
    client_id: string,
  ): Demand[] {
    if (!demands?.length) return [];
    if (!groupByArray.length) return demands;

    const groupBysObject = recursiveGroupBy(
      demands,
      _.map(groupByArray, (groupBy: StocksGroupBy) =>
        getField(groupBy.field, client_id),
      ),
    );

    const breadcrumbsFilteredObject = recursiveBreadcrumb(groupBysObject, [
      ...breadcrumbs,
    ]);

    return breadcrumbsFilteredObject.map(
      ({isOperation, array, children_count}: GroupedByObject) => {
        const [firstDemand] = array;
        // last level for the demands
        if (isOperation) return firstDemand;
        // aggregates data for the grouped display
        return {
          ...firstDemand,
          siblings: [...array],
          ...[
            "planned_demand",
            "raw_demand",
            "planned_quantite_op",
            "raw_quantite_op",
            "planned_quantite_op_2",
            "raw_quantite_op_2",
          ].reduce((acc, field) => {
            acc[field] = _.sumBy(array, (demand) => n(demand[field], 0));
            return acc;
          }, {}),
          children_count,
        };
      },
    );
  }

  const getStocksTableHeaders = computed(() => {
    const displayedDataType = getChildrenPrimaryKey(
      getStocksBreadcrumbs.value.at(-1)?.field,
      true,
    );

    const field = displayedDataType || getStocksGroupBy.value?.field;

    let columns: GenericTableColumn[] = [
      {
        name: t(`Stocks.headers.${field}`),
        field,
        type: getFirstColumnTypeFromField(field),
      },
      {
        name: t("Stocks.headers.product_demand"),
        field: "demande",
        type: "number",
      },
      {
        name: t("Stocks.headers.product_initial_stock"),
        field: "initial_stock",
      },
      {
        name: t("Stocks.headers.product_wip"),
        field: "wip",
      },
      {
        name: t("Stocks.headers.product_production_objective"),
        field: "objective",
      },
      {
        name: t("Stocks.headers.product_final_wip"),
        field: "final_wip",
      },
      {
        name: t("Stocks.headers.product_final_stock"),
        field: "final_stock",
      },
      {
        name: t("Stocks.headers.product_coverage"),
        field: "cover",
        type: "number",
      },
      // disabled for now (OPL-6632)
      // {name: t("Stocks.headers.product_status"), field: "status"},
    ];

    if (field === STOCKS_LOWEST_LEVEL_FIELD_NAME) {
      columns.push({
        name: t("Stocks.headers.product_secteur_name"),
        field: "secteur_name",
      });

      columns = columns.filter(
        ({field}) =>
          !["wip", "weighted_wip", "final_wip", "weighted_final_wip"].includes(
            field,
          ),
      );
    }

    const mapColumnName = ({field, name}: GenericTableColumn): string => {
      if (field === "cover") return `${name} (${t("global.days")})`;

      return STOCKS_VALUATION_WEIGHTED_FIELDS.includes(field)
        ? `${name} (${getCurrentStocksLoadQuantityFieldUnit.value})`
        : name;
    };

    // We target the proper demande/objective fields for secondary stocks unit
    const mapColumnField = (field): string => {
      if (!isSecondaryStocksUnit.value) return field;

      let fieldResult = field;
      if (["demande", "objective"].includes(field)) {
        fieldResult = currentStocksLoadQuantityField.value.replace(
          "quantite",
          field,
        );
      }

      if (STOCKS_VALUATION_WEIGHTED_FIELDS.includes(fieldResult))
        fieldResult = `${STOCKS_VALUATION_FIELDS_PREFIX}${fieldResult}`;

      return fieldResult;
    };

    return columns.map(({field, name, ...column}: GenericTableColumn) => ({
      ...column,
      name: mapColumnName({field, name}),
      field: mapColumnField(field),
    }));
  });

  const stocksGroupByOptions = computed(() =>
    Array.from(
      STOCKS_TABLE_VIEWS.filter((view: string) => view !== "secteur_id"),
      (view: string): StocksGroupBy => ({
        name: t(`Stocks.lists.stocks_table_view_${view}`),
        field: view,
      }),
    ),
  );

  const stocksFilterByOptions = computed<StocksFilter[]>(() =>
    Array.from(
      [null, /*"shortage", "overload",*/ "best", "with_cmj"],
      (criterion: string): StocksFilter => ({
        name: t(`Stocks.lists.products_status_${criterion}`),
        criterion,
      }),
    ),
  );

  function redirectToOperationSector(product: Product) {
    const matchingSector = stations.value.find(
      ({id}) => id === product.secteur_id,
    );
    if (!matchingSector) return;
    updateBreadcrumbs(matchingSector, null, {
      simulationId: simulation.value.id,
    });
  }

  return {
    getDemandFieldValue,
    getField,
    groupDemands,
    getDetailedWeekLabel,
    getStocksTableHeaders,
    redirectToOperationSector,
    getDetailedWeekLabelFromPeriodObject,
    stocksGroupByOptions,
    stocksFilterByOptions,
  };
}
