<template>
  <div
    :class="['favorite-row', {current: isCurrentSector}]"
    :data-testid="`favorite-row-${sector.id}`"
  >
    <div class="flex-1 fd-flex-center gap-4" @click="onSectorClick">
      <div class="text-ellipsis">
        {{ sector.name }}
      </div>
    </div>

    <vue-feather
      type="star"
      size="14"
      v-bind="iconColor"
      @click.stop="() => toggleFav()"
    />
  </div>
</template>

<script lang="ts">
import {storeToRefs} from "pinia";
import {useFav} from "@/composables/useFav";
import {useNavigation} from "@/composables/useNavigation";
import {FeatherIconProps, Sector} from "@/interfaces";
import {levelCorresp} from "@oplit/shared-module";
import {PropType, defineComponent} from "vue";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "FavoriteRow",
  props: {
    sector: {type: Object as PropType<Sector>, required: true},
    level: {type: Number, required: true},
  },
  setup(props) {
    const {updateBreadcrumbs} = useNavigation();
    const {toggleFav} = useFav(props.sector);

    const mainStore = useMainStore();
    const {userData, breadcrumbs, simulation, stations, variables} =
      storeToRefs(mainStore);

    return {
      updateBreadcrumbs,
      toggleFav,
      userData,
      breadcrumbs,
      simulation,
      stations,
      variables,
    };
  },
  inject: {
    preventRedirect: {
      default: () => false,
    },
    /**
     * validator function called before updating/cropping the breadcrumb
     * passed as an inject for the current use case (parameters route navigation with unsaved changes)
     */
    beforeChange: {
      default: () => () => true,
    },
  },
  computed: {
    iconColor(): FeatherIconProps {
      const {
        variables: {newYellowRegular},
      } = this;

      if (!this.isFavoriteSector(this.sector)) return;
      return {
        fill: newYellowRegular,
        stroke: newYellowRegular,
      };
    },
    // returns true if props.sector is current app one
    isCurrentSector(): boolean {
      return this.breadcrumbs.at(-1).id === this.sector.id;
    },
  },
  methods: {
    // returns whether or not @sector is favorited by the current user
    isFavoriteSector(sector: Sector = this.sector): boolean {
      return this.userData.favorites?.find(
        ({secteur_id}: {secteur_id: string}): boolean =>
          secteur_id === sector.id,
      );
    },
    // triggered upon clicking on a favorite sector
    async onSectorClick(): Promise<void> {
      const {sector, preventRedirect, simulation} = this;
      const success = await this.beforeChange();
      if (!success) return;
      this.updateBreadcrumbs(sector, levelCorresp[sector.level], {
        simulationId: simulation?.id,
        stayInPage: preventRedirect,
      });
    },
  },
});
</script>

<style scoped lang="scss">
.favorite-row {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  color: rgb(var(--v-theme-newMainText));

  padding: 4px calc(var(--g-scrollbar-area) * 0.75) 4px 16px;
  line-height: 2;
  transition: all 0.25s;

  &:hover,
  &.current {
    background: rgb(var(--v-theme-newHover));
  }

  &.current {
    font-weight: bold;
  }
}
</style>
