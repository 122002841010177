<template>
  <v-tooltip location="bottom">
    <template v-slot:activator="{props}">
      <div v-bind="props" class="breadcrumbs-wrapper">
        <div
          v-for="(breadcrumb, index) in updatedBreadcrumbs"
          :key="breadcrumb.id"
          class="fd-flex-center gap-2"
          :data-testid="`${TEST_IDS.SECTOR_BREADCRUMBS__LEVEL_PREFIX}${
            index + 1
          }`"
        >
          <SectorBreadcrumb
            :breadcrumb="breadcrumb"
            :show-fav="index === updatedBreadcrumbs.length - 1"
            :data-testid="`breadcrumb-chevron-${index}`"
            @selected="(b) => cropBreadcrumb(b)"
          />
          <FDropdown
            v-if="(breadcrumb.children || []).length"
            btn-class="last-chevron"
            type="perimeter"
            icon
            small
            prepend-icon="chevron-right"
            :size="14"
            width="320px"
            hide-chevron
            is-tag
            :on-change="onBreadcrumbChange"
            :forced-level="breadcrumb"
            hide-search
            keep-scrollbar
            :data-testid="`breadcrumb-sectors-${index}`"
          />
        </div>
      </div>
    </template>
    <span>
      {{ (updatedBreadcrumbs || []).map((x) => x.name).join(" > ") }}
    </span>
  </v-tooltip>
</template>

<script lang="ts">
import {storeToRefs} from "pinia";
import {FDropdown} from "@/components/Global/Homemade";
import {useNavigation} from "@/composables/useNavigation";
import {TEST_IDS} from "@/config/constants";
import {OplitLevel, Sector} from "@/interfaces";
import {defineComponent} from "vue";
import SectorBreadcrumb from "./SectorBreadcrumb.vue";
import {useMainStore} from "@/stores/mainStore";
import {useRouteAssertions} from "@/composables/useRouteAssertions";

export default defineComponent({
  components: {
    FDropdown,
    SectorBreadcrumb,
  },
  setup() {
    const mainStore = useMainStore();
    const {breadcrumbs, simulation, variables, team, isDevEnv, perimeters} =
      storeToRefs(mainStore);
    const {setBreadcrumbs} = mainStore;

    const {updateBreadcrumbs} = useNavigation();
    const {isParametersRoute} = useRouteAssertions();

    return {
      updateBreadcrumbs,
      breadcrumbs,
      simulation,
      variables,
      TEST_IDS,
      setBreadcrumbs,
      team,
      isDevEnv,
      perimeters,
      isParametersRoute,
    };
  },
  /**
   * validator function called before updating/cropping the breadcrumb
   * passed as an inject for the current use case (parameters route navigation with unsaved changes)
   */
  inject: {
    beforeChange: {
      default: () => () => true,
    },
  },
  computed: {
    updatedBreadcrumbs(): Sector[] {
      const updatedBreadcrumbs = this.breadcrumbs.map((b: any) => {
        if (!b?.collection) return b;
        const match =
          this.perimeters[b.collection]?.find((x: any) => x?.id === b.id) || {};
        return {
          ...b,
          ...match,
          children: (match.children || []).filter(
            (c: any) => !["removed", "deleted"].includes(c.status),
          ),
        };
      });
      return updatedBreadcrumbs;
    },
    computedSimulationId(): string {
      const {simulation} = this;
      return simulation?.id;
    },
  },
  methods: {
    async onBreadcrumbChange(sector: Sector, level: number | OplitLevel) {
      const success = await this.beforeChange();
      if (!success) return;
      const routeName = this.isParametersRoute ? "parametres" : undefined;
      this.updateBreadcrumbs(sector, level, {
        stayInPage: true,
        simulationId: this.computedSimulationId,
        forceRouteName: routeName,
      });
    },
    async cropBreadcrumb(item: Sector): Promise<void> {
      if (!item || item.disabled || !this.breadcrumbs?.length) return;
      const success = await this.beforeChange();
      if (!success) return;
      const breadcrumbs = this.breadcrumbs
        .filter((x: any) => item[x.type + "_id"] === x.id || item.id === x.id)
        .map((x: any, i: number, arr: any) => ({
          ...x,
          disabled: i === arr.length - 1,
        }));
      this.setBreadcrumbs(breadcrumbs);
      const [secteur] = breadcrumbs.slice(-1);
      if (this.$route.query?.id !== secteur.id) {
        this.$router.replace({
          query: {
            ...(this.$route.query || {}),
            id: secteur.id,
            collection: secteur.collection,
            level: secteur.level,
          },
        });
      }
    },
  },
});
</script>

<style lang="scss">
.breadcrumbs-wrapper {
  display: flex;

  .current {
    .last-chevron {
      display: none;
    }
    &:hover .last-chevron {
      display: flex;
    }
  }
}

.breadcrumb-content {
  &.button.v-btn {
    &.v-btn--disabled .button-text {
      color: rgb(var(--v-theme-newMainText)) !important;
      font-weight: 600;
      font-size: 14px;
    }

    & .button-text {
      color: rgb(var(--v-theme-newDisableText)) !important;
    }
  }
}
</style>
