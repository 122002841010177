<template>
  <div class="gantt-row-header--wrapper">
    <div class="gantt-header text-14" style="position: relative">
      <div class="gantt-header--days-wrapper">
        <div
          v-for="day in ganttDaysArray"
          :key="`day-${day}`"
          :class="[
            {
              'is-last-week-day': isLastWeekDay(day),
              'is-last-displayed-day': isLastDisplayedDay(day),
            },
            'gantt-header--day-wrapper',
          ]"
        >
          <div v-if="!hideDailyLoadRate" :class="getDailyLoadRateClasses(day)">
            <CapaTooltip
              :capa-percentage="getDailyLoadRate(day)"
              :computed-charge="dailyLoadRates[day]?.load"
              :charge-table="dailyLoadRates[day]?.loadDetail"
              :computed-capa="dailyLoadRates[day]?.capa"
              :unit="dailyLoadRates[day]?.unit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject, PropType} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import CapaTooltip from "@/components/Scheduling/Capacity/CapaTooltip.vue";
import {
  CSS_SCHEDULING_GANTT_CELL_CLASS,
  SCHEDULING_GANTT_MESHES_OPTIONS,
  SCHEDULING_GANTT_SHIFTS_OPTIONS,
} from "@/config/constants";
import type {HTMLElementStyleObject, VueClassesArray} from "@/interfaces";
import moment from "moment";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useGanttStore} from "@/stores/ganttStore";

import {useMainStore} from "@/stores/mainStore";

// FIXME
// const format = "H:mm";
const monthFormat = "MMMM YYYY";
const defaultShifts = {
  morning: "6:00",
  afternoon: "14:00",
  night: "22:00",
};

const defaultHours = {
  morning: "0:00",
  afternoon: "8:00",
  night: "16:00",
};

export default defineComponent({
  name: "gantt-row-header",
  components: {CapaTooltip},
  props: {
    dailyLoadRates: {
      type: Object as PropType<
        Record<
          string,
          {
            value: string | number;
            load: number;
            capa: number;
            unit: string;
            loadDetail: {of_id: string; load: number}[];
            mappedOpsWithDailyLoad: {
              of_id: string;
              load: number;
            }[];
          }
        >
      >,
      required: true,
    },
    hideDailyLoadRate: {type: Boolean, default: false},
    diagramLeftScroll: {type: Number, default: 0},
  },
  setup(props) {
    const getGanttIsPastDelayShown = inject("getGanttIsPastDelayShown");

    const {getDailySchedulingColors} = useSchedulingStore();
    const {
      ganttMesh,
      ganttHideWeekend,
      ganttCellWidth,
      ganttDaysArray,
      ganttDaysMomentFormat,
    } = storeToRefs(useGanttStore());

    const mainStore = useMainStore();
    const {clientParameters} = storeToRefs(mainStore);

    function isClosedDay(day: string) {
      return props.dailyLoadRates[day]?.capa === 0;
    }

    return {
      SCHEDULING_GANTT_MESHES_OPTIONS,
      SCHEDULING_GANTT_SHIFTS_OPTIONS,
      getDailySchedulingColors,
      ganttMesh,
      ganttHideWeekend,
      getGanttIsPastDelayShown,
      ganttCellWidth,
      ganttDaysArray,
      ganttDaysMomentFormat,
      clientParameters,
      isClosedDay,
    };
  },
  computed: {
    displayedMeshes(): string[] {
      const daysArray = Array.from(
        this.ganttDaysArray,
        (date: string): string => moment(date).format(monthFormat),
      );
      return Array.from(_.uniq(daysArray), _.capitalize);
    },
    daySegments() {
      const segments = this.clientParameters.has_gantt_shift_view
        ? // FIXME: replace `this.clientParameters.shifts` with correct fb object once the shift customization is available
          this.clientParameters.shifts ?? defaultShifts
        : defaultHours;

      const segmentsEntries = this.clientParameters.has_gantt_shift_view
        ? Object.entries(SCHEDULING_GANTT_SHIFTS_OPTIONS)
        : Object.entries(defaultHours);

      return Array.from(
        segmentsEntries,
        ([key, label]: [key: string, label: string], index: number) => ({
          label,
          key,
          value: segments[key],
          tooltip: [
            segments[key],
            // start hour of the next segment
            segments[segmentsEntries[(index + 1) % segmentsEntries.length][0]],
          ].join(" - "),
        }),
      );
    },
  },
  methods: {
    /**
     * this method is used to apply specific style to the displayed meshes in function of the current left scroll status of the diagram
     * upon moving far enough, the first `fixed` displayed mesh will be replaced with the new correct one
     */
    isFirstMeshDisplayed(mesh: string, index: number): boolean {
      if (this.displayedMeshes.length === 1) return true;
      const currentMonthOffset: number = this.getMeshLeftOffset(mesh);
      const nextMonthOffset: number = this.getMeshLeftOffset(
        this.displayedMeshes[index + 1],
      );
      // offset equivalent to the left padding for smoother display
      const leftValue: number = this.diagramLeftScroll + 12;
      const defaultCondition = leftValue >= currentMonthOffset;
      if (!nextMonthOffset) return defaultCondition;
      return defaultCondition && leftValue < nextMonthOffset;
    },
    getMeshLeftOffset(mesh: string): number {
      const {ganttDaysArray, ganttCellWidth} = this;

      const previousDaysLength = ganttDaysArray.filter((day: string) =>
        moment(day).isBefore(moment(mesh, monthFormat)),
      ).length;

      return previousDaysLength * ganttCellWidth;
    },
    getMeshStyle(mesh: string, index: number): HTMLElementStyleObject {
      if (this.isFirstMeshDisplayed(mesh, index)) return;
      const leftOffset = this.getMeshLeftOffset(mesh);
      /**
       * there are scenarios where the last mesh text overflows from the header
       * it occurs when we reach a new month at the end of the table for the month view
       * we check that the remaining space is enough for the display, otherwise we hide it through css
       */
      const clientWidth = this.$el?.clientWidth ?? 0;
      // this value accounts for the container & the mesh text horizontal paddings
      const xPaddingSums = 48;
      const maxWidth = clientWidth - leftOffset - xPaddingSums;
      if (maxWidth < 0) return {display: "none"};

      return {
        left: `${leftOffset}px`,
        "max-width": `${maxWidth}px`,
        height: 0,
      };
    },
    isLastWeekDay(day: string): boolean {
      return moment(day).isoWeekday() === (this.ganttHideWeekend ? 5 : 7);
    },
    isLastDisplayedDay(day: string): boolean {
      return day === this.ganttDaysArray[this.ganttDaysArray.length - 1];
    },
    getDisplayedDayValue(day: string, index: number): string {
      const dayText = moment(day).format(this.ganttDaysMomentFormat);
      if (!this.getGanttIsPastDelayShown || index) return dayText;
      return `≤ ${dayText}`;
    },
    /**
     * @returns an array of classes for the day sub-cells
     */
    getDayClasses(day: string): VueClassesArray {
      const classes = [CSS_SCHEDULING_GANTT_CELL_CLASS, "gantt-header--day"];
      // common arguments for the moment methods
      const args = [moment(day), "day"];

      const isPastDay = moment().isAfter(...args);
      const dailySchedulingColors = this.getDailySchedulingColors(
        day,
        isPastDay,
      );

      if (dailySchedulingColors) return [...classes, dailySchedulingColors];

      if (isPastDay) return [...classes, "bg-newDisableBG text-newDisableText"];

      if (this.isClosedDay(day)) return [...classes, "bg-newLightGrey"];

      const isToday = moment().isSame(...args);
      if (isToday) return [...classes, "is-today"];

      return classes;
    },
    /**
     * TODO:
     * @returns a numeric value representing the daily load rate for the corresponding day
     */
    getDailyLoadRate(day: string): string {
      const {value, load, capa} = this.dailyLoadRates[day] || {};
      if (capa === undefined) return this.$t("global.closed");
      if (load && !capa) return this.$t("global.infinity");
      return `${value || 0}%`;
    },
    /**
     * @returns an array of classes for the daily load sub-cells
     */
    getDailyLoadRateClasses(formattedDate: string): VueClassesArray {
      const classes = [
        CSS_SCHEDULING_GANTT_CELL_CLASS,
        "gantt-header--load-rate",
        "text-ellipsis", // for very high value cases
      ];

      const {value, load, capa} = this.dailyLoadRates[formattedDate] || {};
      if (capa === undefined) return [...classes, "bg-newLightGrey"];
      if (!load || value <= 100) return [...classes, "bg-newLayerBackground"];

      return [...classes, "bg-newPinkLight2 text-newPinkRegular"];
    },
  },
});
</script>

<style scoped lang="scss">
.gantt-row-header--wrapper {
  position: sticky;
  top: var(--gantt-total-header-height);
  display: inline-flex;
  z-index: 3;
  background: rgb(var(--v-theme-newLayerBackground));

  .gantt-header {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    & .gantt-header--days-wrapper {
      display: flex;
      align-items: center;
      overflow: hidden;
      border-bottom: var(--gantt-border);

      & .gantt-header--day-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;

        &.is-last-week-day:not(:last-child)::after,
        &.is-last-displayed-day::after {
          content: "";
          position: absolute;
          top: 0;
          right: -1px;
          width: 1px;
          height: 100%;
          border-right: var(--gantt-border);
          z-index: 1;
        }
        &.is-last-displayed-day::after {
          right: 0;
        }

        & .gantt-header--day {
          border-bottom: var(--gantt-border);
          padding: 8px 0;
          text-align: center;
          background: rgb(var(--v-theme-newLayerBackground));

          &.is-today {
            color: rgb(var(--v-theme-newLayerBackground));
          }
        }

        & .gantt-header--load-rate {
          display: flex;
          justify-content: center;
          font-size: 12px;
          line-height: 20px;
        }

        // remove the very last shift's border-right to prevent overlapping with the one of .gantt-header--days-wrapper
        &.is-last-week-day,
        &:last-child {
          & .gantt-header--segments > div:last-child {
            border-right: none;
            width: calc(var(--gantt-mesh-month-cell-width) - 1px);
          }
        }
      }
    }
  }
}

.plan-wrapper {
  & .gantt-header--day.is-today {
    background: rgb(var(--v-theme-newPrimaryRegular)) !important;
  }
}

.piloting-wrapper {
  & .gantt-header--day.is-today {
    background: rgb(var(--v-theme-newOrangeRegular)) !important;
  }
  & .gantt-row--cell.is-today {
    background: rgb(var(--v-theme-newOrangeLight1)) !important;
  }
}

// overriding the height of the ::before element when displaying the shifts
// the 1px is the bottom border width applied to their wrapping element
.gantt-diagram--wrapper.mesh-week {
  /* FIXME: why ? */
  & .gantt-row__lazy-wrapper:nth-child(2) {
    margin-top: -6px;
  }

  & .gantt-header--wrapper {
    &::before,
    &::after {
      content: "";
      position: sticky;
      top: inherit;
      left: var(--gantt-prefix-width);
      width: 1px;
      height: calc(
        var(--gantt-header-height) + var(--gantt-shifts-line-height) + 1px
      );
      background: var(--gantt-border-color);
      border-top-left-radius: 8px;
      z-index: 2;
    }

    &::before {
      left: var(--gantt-prefix-width);
      border-top-left-radius: 8px;
    }

    &::after {
      right: 0;
    }
  }

  & .gantt-header--days-wrapper {
    // FIXME: temp for nicer display
    border-radius: 0;
  }
}

// overriding the border-radius when displaying the GanttHeaderMonth component, which displays the rounded borders
.gantt-header--wrapper
  .gantt-header
  .gantt-header--meshes-wrapper
  + .gantt-header--days-wrapper {
  border-radius: 0;
}
</style>
