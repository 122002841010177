<template>
  <div>
    <div v-if="loading" class="d-flex mt-5">
      <v-progress-circular class="ma-auto" indeterminate color="primary" />
    </div>
    <div>
      <div class="w-100">
        <div class="font-weight-bold text-newSubText">
          {{ $t("scheduling.machine_center") }}
        </div>
        <div class="mt-2 w-50 fd-flex-center w-100">
          <SectorsSelector
            only-dropdown
            content-class="force-max-height"
            class="flux-data-body-sectors-selector"
            :class="CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS"
            :can_make_change="
              can_make_change && currentPermissions.scheduling.update_of_machine
            "
            :selected-machine-prop="computedMachineCenter"
            :z-index="zIndex"
            :data-testid="TEST_IDS.FLUX_DATA_BODY__SECTORS_SELECTOR"
            @update-sector="setMachineCenter"
          />
          <div v-if="op.ops_w_same_sequence" class="ml-2">
            {{
              $t("scheduling.op_on_multiple_sectors", {
                count: op.ops_w_same_sequence,
              })
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-4">
      <span>
        <div class="font-weight-bold text-newSubText">
          {{ $t("scheduling.report_incident") }}
        </div>
        <v-menu
          :disabled="
            !can_make_change ||
            !currentPermissions.scheduling.create_update_of_tags
          "
          :z-index="zIndex"
          location="bottom"
          offset="8"
        >
          <template v-slot:activator="{props}">
            <v-chip
              v-bind="props"
              class="mt-2 px-4 rounded-pill cursor-pointer incident-chip"
              :color="computedFault ? 'newPinkRegular' : 'newMainText'"
              size="small"
              pill
              data-testid="incident-chip"
            >
              <v-img
                v-if="!['none', ''].includes(computedFault)"
                :src="getAssetURL('icons', `${computedFault}.svg`)"
                width="14"
                class="mr-2"
                cover
              />
              <span data-testid="incident-chip-label">
                {{ $t(`operation.faults.${computedFault || "none"}`) }}
              </span>
              <vue-feather type="edit-2" tag="div" class="ml-2" size="12" />
            </v-chip>
          </template>
          <div :class="CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS">
            <v-chip
              v-for="f in faults"
              :key="f"
              class="ma-2 px-2 cursor-pointer d-block incident-dropdown-chip"
              :color="f === 'none' ? 'newMainText' : 'newPinkRegular'"
              size="small"
              :data-testid="`incident-chip-${f}`"
              @click="setFault(f)"
            >
              <v-img
                v-if="f !== 'none'"
                :src="getAssetURL('icons', `${f}.svg`)"
                width="14"
                class="mr-2"
                cover
              />
              {{ $t("operation.faults." + f) }}
            </v-chip>
          </div>
        </v-menu>
      </span>
      <span class="ml-4">
        <div class="font-weight-bold text-newSubText">
          {{ $t("global.status") }}
        </div>
        <v-menu
          :disabled="
            !can_make_change || !currentPermissions.scheduling.update_of_status
          "
          :z-index="zIndex"
          location="bottom"
          offset="8"
        >
          <template v-slot:activator="{props}">
            <v-chip
              v-bind="props"
              class="mr-1 mt-2 px-4 rounded-pill cursor-pointer status-chip"
              :color="computeStatusColorText(displayedOperationStatus.text)"
              :variant="
                displayedOperationStatus.text === 'done' ? 'elevated' : 'tonal'
              "
              size="small"
              :disabled="disabledStatusPicker"
              :data-testid="TEST_IDS.FLUX_DATA_BODY__STATUS_CHIP"
            >
              <span>{{
                $t(`operation.status.${displayedOperationStatus.text}`)
              }}</span>
              <span v-if="displayedOperationStatus.append" class="ml-1">
                {{ displayedOperationStatus.append }}
              </span>
              <vue-feather type="edit-2" tag="div" class="ml-2" size="12" />
            </v-chip>
          </template>
          <div
            :class="CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS"
            class="d-flex flex-column gap-4 pa-2"
          >
            <v-chip
              v-for="s in status"
              :key="s"
              class="cursor-pointer"
              :color="computeStatusColorText(s, true)"
              :variant="s === 'done' ? 'elevated' : 'tonal'"
              size="small"
              @click="setStatus(s)"
            >
              {{ $t("operation.status." + s) }}
            </v-chip>
          </div>
        </v-menu>
      </span>
    </div>
    <div class="mt-4">
      <div class="font-weight-bold text-newSubText">
        {{ $t("scheduling.quantity_realized") }}
      </div>
      <div class="mt-2 d-flex">
        <FTextField
          :model-value="computedProduced"
          dense
          outlined
          class="mr-1 w-50"
          :disabled="!can_make_change || isDisabledQuantityProduced"
          @change="setQttyProduced"
        />
        <span class="pa-2 font-weight-bold text-newSubText">
          | {{ quantiteOF }} {{ getOFUnit(op) }}</span
        >
      </div>
    </div>
    <div class="mt-4 d-flex gap-6">
      <div class="d-flex flex-column gap-4 flex-1">
        <div class="fd-flex-center">
          <vue-feather
            type="calendar"
            tag="div"
            class="mr-2"
            size="18"
            :stroke="variables.newSubText"
          />
          <div class="font-weight-bold text-newSubText">
            {{ $t("scheduling.date_schedule") }}
          </div>
        </div>

        <FDatePicker
          class="w-100"
          :date-value="computedDate"
          :field-value="selectedDate"
          :disabled="
            !can_make_change || !currentPermissions.scheduling.update_of_date
          "
          :placeholder="$t('Simulation.enter_a_date')"
          :handle-change="handleDateChange"
          can-select-shift
          @validate="handleValidate"
        />
      </div>

      <div class="d-flex flex-column gap-4 flex-1">
        <div class="fd-flex-center">
          <vue-feather
            type="clock"
            tag="div"
            class="mr-2"
            size="18"
            :stroke="variables.newSubText"
          />
          <div class="font-weight-bold text-newSubText">
            <span>{{ $t("scheduling.duration") }}</span>
            <span v-if="computedOPUnit !== null"> ({{ computedOPUnit }})</span>
          </div>
        </div>
        <span>
          <FTextField
            :model-value="
              prettyNumeral(
                getOperationQuantity(
                  op,
                  clientParameters.has_gantt_load_adjustment,
                ),
              )
            "
            outlined
            disabled
            dense
            class="mr-2"
            :placeholder="$t('global.default')"
            @change="setQuantite"
          />
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import _ from "lodash";
import {PropType, defineComponent, ref, toRefs} from "vue";
import {storeToRefs} from "pinia";
import moment from "moment";
import numeral from "numeral";
import {
  getMaxStatus,
  includeOriginalUpdateShiftPlanningInSiblingDate,
  periodeToText,
  pgOpsMapFn,
  getOFUnit,
} from "@oplit/shared-module";
import {
  schedulingStatusColorText,
  schedulingStatusColorBg,
  getOperationQuantity,
} from "@/tscript/utils/schedulingUtils";
import {FTextField} from "@/components/Global";
import FDatePicker from "@/components/Global/FDatePicker.vue";
import {SectorsSelector} from "@/components/Scheduling/Navbar/NavbarComponents";
import {CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS} from "@/config/constants";
import {getAssetURL} from "@/tscript/utils/generalHelpers";
import {usePermissionsStore} from "@/stores/permissionsStore";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {OF_STATUS, TEST_IDS} from "@/config/constants";

import {useMainStore} from "@/stores/mainStore";
import {MachineCenter} from "@/interfaces";
import {SchedulingOperation} from "@/interfaces";
import {useOperationStatus} from "@/composables/scheduling/useOperationStatus";

numeral.locale("fr");

export default defineComponent({
  name: "flux-data-body",
  components: {
    FTextField,
    SectorsSelector,
    FDatePicker,
  },
  props: {
    op: {
      type: Object as PropType<SchedulingOperation>,
      default: () => ({} as SchedulingOperation),
    },
    can_make_change: {type: Boolean, default: false},
    zIndex: {type: Number, default: 2000},
    propagateOpsChanges: {type: Function, default: () => ({})},
  },
  setup(props) {
    const schedulingStore = useSchedulingStore();
    const {sendSchedulingLoadEventToBackend} = schedulingStore;
    const {
      selectedSimulation,
      schedulingMachineCentersAndTags,
      shouldHandleOPDuration,
    } = storeToRefs(schedulingStore);
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {operationComputedOpQuantityUnit} = useComputeQuantityUnit();

    const mainStore = useMainStore();
    const {
      userData,
      apiClient,
      perimeters,
      isDevEnv,
      clientParameters,
      variables,
      stations,
    } = storeToRefs(mainStore);
    const {getCtxNextAvailableDate, getCtxSectorOrderedCalendars} = mainStore;
    const {op} = toRefs(props);
    const {displayedOperationStatus} = useOperationStatus(op);

    return {
      status: ref<string[]>(Object.values(OF_STATUS)),
      faults: ref<string[]>([
        "none",
        "breakdown",
        "no-raw-material",
        "tools",
        "component-rupture",
        "quality",
        "other",
      ]),
      time: ref<string>(""),
      quantite: ref<number>(null),
      quantiteOF: ref<number>(null),
      uniteOF: ref<string>(null),
      selectedDate: ref<string>(""),
      tabs: ref<{name: string}[]>([
        {name: "global.general"},
        {name: "scheduling.capa"},
        {name: "scheduling.comments"},
      ]),
      loading: ref<boolean>(false),
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      selectedSimulation,
      schedulingMachineCentersAndTags,
      currentPermissions,
      operationComputedOpQuantityUnit,
      displayedOperationStatus,
      userData,
      apiClient,
      perimeters,
      isDevEnv,
      clientParameters,
      variables,
      stations,
      getCtxNextAvailableDate,
      getCtxSectorOrderedCalendars,
      shouldHandleOPDuration,
      sendSchedulingLoadEventToBackend,
      getOFUnit,
      TEST_IDS,
    };
  },
  computed: {
    isDisabledQuantityProduced(): boolean {
      /**
       * we allow the modification of the quantity produced even in archived simulations
       * this condition used to be the below
       * return !this.can_make_change
       */
      return false;
    },
    computedDate() {
      const debutPeriode = moment(periodeToText(this.selectedDate)).format(
        "YYYY-MM-DD",
      );
      return debutPeriode;
    },
    computedFault() {
      const {op, faults} = this;
      const fault = op?.new_fault ?? op?.fault;
      return faults.includes(fault) ? fault : "";
    },
    computedMachineCenter() {
      const {schedulingMachineCentersAndTags, computedSectorId} = this;
      return schedulingMachineCentersAndTags.find(
        (s: MachineCenter) => s.secteur_id === computedSectorId,
      );
    },
    computedSectorId() {
      const {op} = this;
      return op?.new_secteur_id || op?.secteur_id;
    },
    computedProduced() {
      const {op: operation} = this;
      const quantityProduced = operation?.quantity_produced || 0;
      return +quantityProduced;
    },
    computedOPUnit() {
      const {op: operation} = this;
      return this.operationComputedOpQuantityUnit(operation);
    },
    disabledStatusPicker() {
      const {
        op: {erp_status},
      } = this;
      return erp_status === "done";
    },
  },
  watch: {
    op: {
      immediate: true,
      handler: function () {
        this.loadData();
      },
    },
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("scrollable-general")?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 100);
  },
  methods: {
    async updateOperation(
      update: Record<string, unknown>,
      should_return_insertion_results = false,
    ) {
      const {shouldHandleOPDuration} = this;
      this.loading = true;
      const {updated_values} = await this.sendSchedulingLoadEventToBackend({
        data: [{initial: this.op, update}],
        should_return_updated_values: true,
        should_handle_op_duration: shouldHandleOPDuration,
        should_return_insertion_results,
      });
      const mappedValues = pgOpsMapFn(updated_values, {
        keepOpsDone: true,
      });
      this.propagateOpsChanges(mappedValues);
      this.loading = false;
    },

    async setMachineCenter(machineCenter: MachineCenter) {
      if (this.loading) return;
      if (!machineCenter?.secteur_id) return;
      const update = {new_secteur_id: machineCenter.secteur_id};
      await this.updateOperation(update);

      this.$segment.value.track("[Ordo] Operation Sector Updated", {
        origin: "sidebar",
      });
    },
    async setFault(value: string) {
      const {computedFault} = this;
      if (this.loading) return;
      let update = {new_fault: value};
      if (computedFault === value || value === "none") update = {new_fault: ""};
      await this.updateOperation(update);

      this.$segment.value.track("[Ordo] Operation Incident Updated", {
        old_value: computedFault,
        new_value: update.new_fault,
      });
    },
    async setStatus(value: string) {
      if (this.loading) return;
      let {quantiteOF} = this;
      let update: Record<string, string | number> = {};
      update.new_status = value;
      if (value === "done") update.quantity_produced = quantiteOF || 0;

      // here we correct the new_status to the most recent one
      const maxStatus = getMaxStatus([value, this.op.erp_status]);
      if (value !== maxStatus) return;

      // We return insertion results to properly trigger recalculation of of_delta,
      // first_active_op_theoric_date and first_active_op_planned_date (needed to properly
      // calculate the updated estimated end date on change)
      await this.updateOperation(update, true);

      this.$segment.value.track("[Ordo] Operation Status Updated", {
        new_value: update?.new_status,
      });
    },
    async setQttyProduced(value: string) {
      if (this.loading) return;
      const update: Record<string, string> = {quantity_produced: value};
      // QttyProduced can be greater than quantiteOF
      if (value >= this.quantiteOF) update.new_status = "done";

      await this.updateOperation(update);

      this.$segment.value.track("[Ordo] Operation Produced Quantity Updated", {
        old_value: this.computedProduced,
        new_value: value,
      });
    },
    async setQuantite(value: string) {
      if (this.loading || !value) return;
      let newValue = value;
      if (newValue.includes(",")) newValue = newValue.replace(/,/g, ".");

      if (newValue.includes(" ")) newValue = newValue.replace(/ /g, "");

      const update = {
        quantite: _.toNumber(newValue),
      };
      await this.updateOperation(update);
    },
    async handleDateChange(date: string) {
      this.selectedDate = date;
    },
    async handleValidate() {
      const {
        clientParameters,
        op,
        getCtxSectorOrderedCalendars,
        getCtxNextAvailableDate,
        shouldHandleOPDuration,
      } = this;
      const {has_gantt_load_adjustment} = clientParameters;
      const {simulation_id, of_id, secteur_id, day_date, op_duration} = op;
      let update: {new_date?: string; first_day_duration?: number} = {};
      if (this.loading) return;

      const opSector = this.stations.find(({id}) => id === secteur_id);
      let nextAvailableDate = getCtxNextAvailableDate(
        this.selectedDate,
        false,
        opSector,
      );
      const isMovingRight = moment(day_date).isBefore(nextAvailableDate);
      let canAdjustLoadToCapa = has_gantt_load_adjustment && isMovingRight;
      if (canAdjustLoadToCapa) {
        const [, result] = await this.apiClient.getNextAvailableCapaDay({
          simulation_id,
          of_ids: [of_id],
          sector_id: secteur_id,
          new_date: nextAvailableDate,
          calendars: getCtxSectorOrderedCalendars(opSector),
          op_duration,
        });
        const {new_date, first_day_duration} = result;
        if (first_day_duration !== 0) {
          nextAvailableDate = new_date;
          update = {
            first_day_duration,
          };
        }
        canAdjustLoadToCapa = canAdjustLoadToCapa && first_day_duration !== 0;
      }
      update = {
        ...update,
        new_date: includeOriginalUpdateShiftPlanningInSiblingDate(
          this.selectedDate,
          nextAvailableDate,
        ),
      };

      //OPL-4456 : nouveau format des évènements d'ordo
      this.loading = true;
      const {updated_values} = await this.sendSchedulingLoadEventToBackend({
        data: [{initial: this.op, update}],
        should_return_updated_values: true,
        should_return_insertion_results: true,
        should_handle_op_duration: shouldHandleOPDuration,
        ...(canAdjustLoadToCapa && {has_gantt_load_adjustment}),
      });
      const mappedValues = pgOpsMapFn(updated_values, {
        keepOpsDone: true,
      });
      this.propagateOpsChanges(mappedValues);
      this.loading = false;

      this.$segment.value.track("[Ordo] Operation Planned Date Updated", {
        origin: "sidebar",
      });
    },
    loadData() {
      let {op} = this;
      if (!op) return;
      this.selectedDate = op.date?.value ?? op.day_date;
      this.quantiteOF = null;
      this.uniteOF = null;

      //Update simulation data
      if (![null, undefined].includes(op?.quantite)) {
        this.quantite = Math.max(0, +op?.quantite || 0);
        this.quantiteOF = _.round(Math.max(0, +op?.quantite || 0), 2);
        this.uniteOF = op.unite;
      }
      if (op?.new_date) this.selectedDate = op?.new_date;
      if (![null, undefined].includes(op.quantite_of)) {
        this.uniteOF = getOFUnit(op);
        this.quantiteOF = _.round(op.quantite_of, 2);
      }
    },
    computeStatusColorBg(status: string, isInModal = false) {
      let result = "";
      if (status == this.displayedOperationStatus.text || isInModal)
        result = schedulingStatusColorBg(status);

      return result;
    },
    computeStatusColorText(status: string, isInModal = false) {
      let result = "";
      // FIXME
      if (status === "done") return "newDisableText";
      if (status == this.displayedOperationStatus.text || isInModal)
        result = schedulingStatusColorText(status);
      return result;
    },

    prettyNumeral(number: number) {
      return numeral(+(number || 0)).format("0.[00]");
    },
    getOperationQuantity,
    getAssetURL,
  },
});
</script>
<style scoped lang="scss">
.horizontal-line {
  display: inline-block;
  width: 1px;
  height: 40px;
  background-color: rgb(var(--v-theme-newSubText));
}
.flux-data-body-sectors-selector :deep(.button) span:last-child {
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
}
.incident-chip {
  height: 32px;
  font-size: 14px;
}
.incident-dropdown-chip {
  width: fit-content;
  font-size: 14px;
}
.status-chip {
  height: 32px;
  font-size: 14px;
}
</style>
