<template>
  <transition name="bounce-in">
    <Teleport
      v-if="isDisplayedActionsMenu"
      :disabled="!teleport"
      :to="teleport"
    >
      <div
        v-if="isDisplayedActionsMenu"
        :class="[
          CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
          {'hide-vertical-arrows': hideVerticalArrows},
          {'hide-edit-dialog': hideEditDialog},
          {'has-shift-arrow--up': hasShiftArrowUp},
          {'has-shift-arrow--down': hasShiftArrowDown},
          {[`bg-${backgroundColor}`]: !!backgroundColor},
          `display-${displaySide}`,
        ]"
        v-bind="$attrs"
        class="operation-card-actions-menu print-hide"
        :style="getActionsMenuStyle"
        data-testid="operation-card-actions-menu"
      >
        <div v-if="isDisplayedArrowsContainer" class="navigation-dot-container">
          <div
            :class="[
              {'is-operation-ongoing': isOperationOngoing},
              'navigation-dot',
            ]"
          >
            <v-progress-circular
              v-if="isOperationOngoing"
              :color="backgroundColor ? 'blanc' : 'newMainText'"
              size="18"
              width="2"
              class="ma-auto"
              indeterminate
            />
            <template v-else>
              <span
                v-for="{type, direction} in displayedArrows"
                :key="`${type}-${direction}`"
                :class="[
                  `${type}--${direction}`,
                  {disabled: type === 'arrow' && isDisabledArrow(direction)},
                ]"
                :data-testid="`operation-card-arrow-${type}-${direction}`"
              >
                <vue-feather
                  :type="`${type}-${direction.toLowerCase()}`"
                  size="16"
                  @click.stop="onArrowClick({type, direction})"
                />
              </span>
            </template>
          </div>
        </div>

        <div class="operations-actions-menu__selected-operations-details">
          <span>
            {{ selectedOpsIDs.length }}
            {{ $t("scheduling.operations", selectedOpsIDs.length) }}
          </span>

          <span>
            {{ prettifyNumber(selectedOpsAggregatedValues.quantite_of) }}
            {{ selectedOpsAggregatedValues.unite_of }}
          </span>

          <span>
            ({{ prettifyNumber(selectedOpsAggregatedValues.quantite_op) }}
            {{ selectedOpsAggregatedValues.unite_op }})
          </span>
        </div>

        <OperationsEditDialog
          v-if="!hideEditDialog"
          :selected-ops-ids="selectedOpsIDs"
        />
      </div>
    </Teleport>
  </transition>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, watch} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import OperationsEditDialog from "@/components/Scheduling/Operations/OperationsEditDialog.vue";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {prettifyNumber} from "@/tscript/utils/generalHelpers";
import {CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS} from "@/config/constants";
import {
  HTMLElementStyleObject,
  OperationsActionsMenuSelectedOps,
} from "@/interfaces";

type PropConfig = {
  type: (typeof Number | typeof String)[];
  default: null | number | string;
};
type Arrow = {
  type: "arrow" | "chevrons";
  direction: "Up" | "Down" | "Left" | "Right";
};

const defaultPositionValue = null;
const rawPositions = ["top", "right", "bottom", "left"];
const positions: [string, PropConfig][] = Array.from(
  rawPositions,
  (direction: string) => [
    direction,
    // props configuration
    {type: [Number, String], default: defaultPositionValue},
  ],
);

export default defineComponent({
  components: {
    OperationsEditDialog,
  },
  props: {
    showActionsMenu: {type: Boolean, default: false},
    displaySide: {type: String, default: "right"},
    // returns a configuration from another object to disable/enable specific keys
    // this is meant to be an item whose keys are the ones in this.arrowDirections
    // and the associated value a boolean that answers "is this arrow displayed ?"
    arrowsState: {
      type: Object,
      default: () => null,
    },
    isOperationOngoing: {type: Boolean, default: false},
    hasDoneOps: {type: Boolean, default: false},
    hideVerticalArrows: {type: Boolean, default: false},
    hideHorizontalArrows: {type: Boolean, default: false},
    hideEditDialog: {type: Boolean, default: false},
    shiftPagination: {
      type: Object as PropType<{current: number; max: number}>,
      default: () => ({current: -1, max: -1}),
    },
    teleport: {type: String, default: null},
    backgroundColor: {type: String, default: null},
    selectedOps: {
      type: Object as PropType<OperationsActionsMenuSelectedOps>,
      default: () => ({} as OperationsActionsMenuSelectedOps),
    },
    /**
     * top & bottom values have effect on the absolute position directly
     * right & left act as offsets (to not interfere with the `displaySide` property)
     */
    ...Object.fromEntries(positions),
  },
  emits: ["trigger-key-event", "change-operation-shift", "is-displayed"],
  setup(props, {emit}) {
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {canOperateOnOperationCards} = storeToRefs(useSchedulingStore());

    const hasShiftArrowUp = computed(() => props.shiftPagination.current > 0);
    const hasShiftArrowDown = computed(
      () => props.shiftPagination.current < props.shiftPagination.max,
    );
    const isDisplayedActionsMenu = computed(() => {
      if (!props.showActionsMenu) return false;
      return canOperateOnOperationCards.value;
    });
    const areEnabledHorizontalArrows = computed(() => {
      if (!currentPermissions.value.scheduling.update_of_date) return false;
      return !props.hideHorizontalArrows;
    });
    const arrowDirections = computed<Arrow["direction"][]>(() => {
      const directions = [];
      if (areEnabledHorizontalArrows.value) directions.push("Right", "Left");
      if (!props.hideVerticalArrows) directions.push("Up", "Down");
      return directions;
    });
    const isDisplayedArrowsContainer = computed(() => {
      if (arrowDirections.value.length === 0) return false;
      return !props.hideHorizontalArrows || !props.hideVerticalArrows;
    });
    const selectedOpsIDs = computed(() => Object.keys(props.selectedOps));
    const selectedOpsAggregatedValues = computed(() => {
      const {unite_of, unite_op} = Object.values(props.selectedOps)[0];

      const {quantite_of, quantite_op} = Object.values(
        props.selectedOps,
      ).reduce(
        (acc, {quantite_of, quantite_op}) => {
          acc.quantite_of += +quantite_of || 0;
          acc.quantite_op += +quantite_op || 0;
          return acc;
        },
        {quantite_of: 0, quantite_op: 0},
      );

      return {
        unite_of,
        unite_op,
        quantite_of: _.round(quantite_of, 2),
        quantite_op: _.round(quantite_op, 2),
      };
    });

    watch(isDisplayedActionsMenu, (v) => emit("is-displayed", v));

    return {
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      currentPermissions,
      hasShiftArrowUp,
      hasShiftArrowDown,
      isDisplayedActionsMenu,
      isDisplayedArrowsContainer,
      arrowDirections,
      selectedOpsIDs,
      selectedOpsAggregatedValues,
      prettifyNumber,
    };
  },
  computed: {
    displayedArrows(): Arrow[] {
      const displayedArrows = this.arrowDirections.map((direction: string) => ({
        type: "arrow",
        direction,
      }));

      if (this.hasShiftArrowUp)
        displayedArrows.unshift({type: "chevrons", direction: "Up"});
      if (this.hasShiftArrowDown)
        displayedArrows.push({type: "chevrons", direction: "Down"});

      return displayedArrows;
    },
    getActionsMenuStyle(): HTMLElementStyleObject {
      const styleObject: HTMLElementStyleObject = {};
      for (const position of rawPositions) {
        if (this[position] !== defaultPositionValue) {
          const property = ["left", "right"].includes(position)
            ? `margin-${position}`
            : position;
          styleObject[property] = Number.isNaN(+this[position])
            ? this[position]
            : `${this[position]}px`;
        }
      }
      return styleObject;
    },
  },
  methods: {
    isDisabledArrow(direction: string): boolean {
      if (this.arrowDirections.indexOf(direction) === -1) return true;
      if (!this.arrowsState) return false;
      /**
       * operations that are done cannot have their `day_date`s updated
       */
      if (["Left", "Right"].includes(direction) && this.hasDoneOps) return true;
      return direction in this.arrowsState && !this.arrowsState[direction];
    },
    onArrowClick({type, direction}: Arrow): void {
      if (type === "arrow" && !this.isDisabledArrow(direction))
        this.$emit("trigger-key-event", `Arrow${direction}`);
      else if (type === "chevrons")
        this.$emit("change-operation-shift", direction.toLowerCase());
    },
  },
});
</script>

<style lang="scss">
$dotContainerPadding: 24px;
$arrowsPadding: 4px;
$actionsMenuWidth: 180px;
.operation-card-actions-menu {
  position: absolute;
  width: $actionsMenuWidth;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: rgb(var(--v-theme-newLayerBackground));
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 12px;
  z-index: 6;
  &.display-right {
    right: #{($actionsMenuWidth + 5) * -1};
  }
  &.display-left {
    left: #{($actionsMenuWidth + 5) * -1};
  }
  & .v-btn {
    background-color: rgb(var(--v-theme-newAppBackground)) !important;
    border: 1px solid rgb(var(--v-theme-newSelected));
    color: rgb(var(--v-theme-newMainText));

    & .button-text > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }

  & .navigation-dot-container {
    padding: $dotContainerPadding;

    & .navigation-dot {
      position: relative;
      margin: auto;
      width: 4px;
      height: 4px;
      background: rgb(var(--v-theme-newDisableText));
      border-radius: 50%;

      &.is-operation-ongoing {
        background: transparent;

        & .v-progress-circular {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      & [class^="arrow--"],
      [class^="chevrons--"] {
        position: absolute;
        cursor: pointer;
        padding: $arrowsPadding; /* allows better click experience */
        display: flex;
        align-items: center;

        & svg {
          stroke: rgb(var(--v-theme-newSubText));
        }

        &.disabled {
          cursor: not-allowed;

          & svg {
            stroke: rgb(var(--v-theme-newSelected)) !important;
          }
        }
      }

      /*
      * somehow the up arrow is not properly placed with the mathematics value
      */
      & .arrow--Up {
        top: #{$dotContainerPadding * -1.1};
        left: 50%;
        transform: translateX(-50%);
      }
      & .arrow--Right {
        right: #{$dotContainerPadding * -1.15 - $arrowsPadding};
        top: 50%;
        transform: translateY(-50%);
      }
      & .arrow--Down {
        bottom: #{$dotContainerPadding * -1.1};
        left: 50%;
        transform: translateX(-50%);
      }
      & .arrow--Left {
        left: #{$dotContainerPadding * -1.15 - $arrowsPadding};
        top: 50%;
        transform: translateY(-50%);
      }
      & .chevrons--Up {
        top: #{$dotContainerPadding * -2};
        left: 50%;
        transform: translateX(-50%);
      }
      & .chevrons--Down {
        bottom: #{$dotContainerPadding * -2};
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  /** present when `backgroundColor` prop is passed */
  &[class*="bg-"] {
    & .navigation-dot {
      background: rgb(var(--v-theme-blanc));

      & svg {
        stroke: rgb(var(--v-theme-blanc)) !important;
      }
    }
  }

  &.hide-vertical-arrows {
    & .navigation-dot-container {
      padding: calc($dotContainerPadding / 2);
    }
  }
  &.has-shift-arrow--up .navigation-dot-container {
    padding-top: $dotContainerPadding * 2;
  }
  &.has-shift-arrow--down .navigation-dot-container {
    padding-bottom: $dotContainerPadding * 1.5;
  }

  & .operations-actions-menu__selected-operations-details {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: normal;
    line-height: 1.25;
  }
}
</style>
