const PARAMETERS_ROOT_ROUTE_NAME = "parameters";
const PARAMETERS_DATA_IMPORT_ROUTE_NAME = "parameters-data-import";

const PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING = {
  root: "parameters-general",
  factoryStructure: "parameters-general-factory-structure",
  sectorsGroups: "parameters-general-sectors-groups",
  operatorsList: "parameters-general-operators-list",
  machinesList: "parameters-general-machines-list",
  oplitUsers: "parameters-general-oplit-users",
  parametersList: "parameters-general-parameters-list",
  calculusRules: "parameters-general-calculus-rules",
  dailyCapacity: "parameters-general-daily-capacity",
  schedulingRules: "parameters-general-scheduling-rules",
  planningDisplay: "parameters-general-planning-display",
  schedulingDisplay: "parameters-general-scheduling-display",
  stocks: "parameters-general-stocks",
  nomenclatures: "parameters-general-nomenclatures",
  customerProviderDisplay: "parameters-customer-provider-display",
  oplitDateCalculation: "parameters-general-oplit-date-calculation",
  modules: "parameters-general-configuration-modules",
};

const PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING = {
  root: "parameters-by-sector",
  calculusRules: "parameters-by-sector-calculus-rules",
  calendar: "parameters-by-sector-calendar",
  schedulingRules: "parameters-by-sector-scheduling-rules",
  sectorInformation: "parameters-by-sector-sector-information",
  standardPlanning: "parameters-by-sector-standard-planning",
  targetStock: "parameters-by-sector-target-stock",
  targetWIP: "parameters-by-sector-target-wip",
  loadFormula: "parameters-by-sector-load-formula",
  dispatchRules: "parameters-by-sector-dispatch-rules",
};

const PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING = {
  root: "parameters-imports",
  importData: "parameters-imports-import-data",
  importRules: "parameters-imports-import-rules",
  importParsingRules: "parameters-imports-import-parsing-rules",
};

const DEFAULT_PARAMETERS_GENERAL_ROUTE =
  PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.factoryStructure;

const DEFAULT_PARAMETERS_BY_SECTOR_ROUTE =
  PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.sectorInformation;

const DEFAULT_PARAMETERS_IMPORTS_ROUTE =
  PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importData;

const SCHEDULING_ROUTES_NAMES_MAPPING = {
  piloting: "scheduling-piloting",
  planning: "scheduling-planning",
};

export {
  PARAMETERS_ROOT_ROUTE_NAME,
  PARAMETERS_DATA_IMPORT_ROUTE_NAME,
  PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING,
  PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING,
  DEFAULT_PARAMETERS_GENERAL_ROUTE,
  DEFAULT_PARAMETERS_BY_SECTOR_ROUTE,
  SCHEDULING_ROUTES_NAMES_MAPPING,
  PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING,
  DEFAULT_PARAMETERS_IMPORTS_ROUTE,
};
