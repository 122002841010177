import {storeToRefs} from "pinia";
import moment from "moment";
import Papa from "papaparse";
import _ from "lodash";

import {clickOnLink} from "@/tscript/utils/exportUtils";
import {
  EXPORT_LOAD_PARSING_IDENTIFIER_MAPPING,
  EXPORT_PROD_PARSING_IDENTIFIER_MAPPING,
  EXPORT_SCHEDULING_LOAD_DEFAULT_MAPPING,
  ImportParsingType,
  getReadableImportParsingRuleValue,
} from "@oplit/shared-module";
import {useParameterStore} from "@/stores/parameterStore";
import {useMainStore} from "@/stores/mainStore";

const useExportGenerateCSV = () => {
  const parameterStore = useParameterStore();
  const mainStore = useMainStore();

  const {importParsingRules} = storeToRefs(parameterStore);
  const {activePerim} = storeToRefs(mainStore);

  function getClientNameByOplitHeader(
    type: ImportParsingType = "LOAD",
    keepAllFields = false,
    forceStandardNaming = false,
  ): {[key: string]: string} {
    const parsingRules = importParsingRules.value[type];

    let clientNameByOplitHeader: {[key: string]: string} = {};
    if (parsingRules && !forceStandardNaming) {
      clientNameByOplitHeader = Object.entries(parsingRules).reduce(
        (
          acc,
          [oplitParsingHeader, {client_field_name, fields, rules, version}],
        ) => {
          // We filter out unused/empty mappings if needed
          if (
            ((!fields?.length && version !== 2) ||
              (!rules?.length && version === 2)) &&
            !keepAllFields
          )
            return acc;

          // Idea: having actually factual identifiers in the parsing rules
          // would be nice - one can dream.
          const defaultMapping =
            type === "LOAD"
              ? EXPORT_LOAD_PARSING_IDENTIFIER_MAPPING
              : EXPORT_PROD_PARSING_IDENTIFIER_MAPPING;
          const oplitInternalHeader =
            defaultMapping[oplitParsingHeader] || oplitParsingHeader;

          return {
            ...acc,
            [oplitInternalHeader]: client_field_name || oplitParsingHeader,
          };
        },
        EXPORT_SCHEDULING_LOAD_DEFAULT_MAPPING as {[key: string]: string}, //initialize the object with the default naming not to loose any key in the process
      );
    } else if (type === "PROD")
      clientNameByOplitHeader = EXPORT_PROD_PARSING_IDENTIFIER_MAPPING;
    else clientNameByOplitHeader = EXPORT_SCHEDULING_LOAD_DEFAULT_MAPPING;

    return clientNameByOplitHeader;
  }

  function generateCSV(
    jsonData: Record<string, unknown>[],
    filename: string,
    params?: {
      pushKeysToFirstRow?: boolean;
      rewriteConcatenatedFields?: boolean;
    },
  ) {
    const {pushKeysToFirstRow = false, rewriteConcatenatedFields = false} =
      params || {};

    if (pushKeysToFirstRow) {
      const allKeys = _.flatten(jsonData.map((x) => _.keys(x)));
      const uniqueKeys = [...new Set(allKeys)];
      uniqueKeys.forEach((key) => {
        if (jsonData[0][key] === undefined) jsonData[0][key] = "";
      });
    }

    // see #getReadableImportParsingRuleValue
    if (rewriteConcatenatedFields) {
      jsonData = jsonData.map((obj) => {
        const newObj: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(obj))
          newObj[key] = getReadableImportParsingRuleValue(value);

        return newObj;
      });
    }

    const blob = new Blob(
      ["\ufeff", Papa.unparse(jsonData, {delimiter: ";"})],
      {
        type: "text/csv;charset=ISO-8859-1;",
      },
    );

    const url = URL.createObjectURL(blob);
    clickOnLink(
      url,
      `${filename || "export"}_${
        activePerim.value?.name || "Oplit"
      }_${moment().format("YYYY_MM_DD_HH_HHmmss")}.csv`,
    );
  }

  return {getClientNameByOplitHeader, generateCSV};
};

export default useExportGenerateCSV;
