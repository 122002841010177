import {storeToRefs} from "pinia";

import {SchedulingOperation} from "@/interfaces";
import {getOperationQuantity} from "@/tscript/utils/schedulingUtils";
import {prettifyNumber} from "@/tscript/utils/generalHelpers";

import {useMainStore} from "@/stores/mainStore";
import {getOFUnit, getOPUnit} from "@oplit/shared-module";

const useComputeQuantityUnit = () => {
  const mainStore = useMainStore();
  const {perimeters, machines, clientParameters} = storeToRefs(mainStore);

  /**
   * Gets the OP unit for a given OP and sector. Will use - as a fallback - the sector's
   * unit or the first assigned sector's unit in the case of a machine.
   * @param {SchedulingOperation} operation - The OP to fetch the unit for.
   * @return {string} The unit for the given OP - by order of priority: the OP's unit,
   * the sector's unit or the default unit.
   */
  function operationComputedOpQuantityUnit(
    operation: SchedulingOperation,
  ): string {
    if (!operation) return;

    const operationCollection =
      operation.collection || operation.secteur_collection;
    let sector = (perimeters.value[operationCollection] || []).find(
      ({id}) => id === operation?.secteur_id,
    );

    if ([null, undefined, "sites"].includes(operationCollection) && !sector) {
      const machine = machines.value.find(
        ({id}) => id === operation.secteur_id,
      );
      const [assignation] = machine?.assignations || [];
      if (!machine || !assignation) return getOPUnit(operation, sector);

      sector = (perimeters.value[assignation.collection] || []).find(
        ({id}) => id === assignation.id,
      );
    }

    return getOPUnit(operation, sector);
  }

  function operationComputedQuantity(
    operation: SchedulingOperation,
    minifiedDisplay = false,
    includeOpQty = true,
  ): {
    unite: string;
    unite_of: string;
    txt: string;
    opQuantityTxt: string;
  } {
    const {quantite_of} = operation;

    const actualQuantity = getOperationQuantity(
      operation,
      clientParameters.value.has_gantt_load_adjustment,
    );

    const opQuantityValue = actualQuantity
      ? prettifyNumber(+actualQuantity)
      : actualQuantity ?? "";
    const opQuantityUnit = operationComputedOpQuantityUnit(operation);
    const opQuantity = `${opQuantityValue} ${opQuantityUnit}`;

    const ofQuantityValue = +quantite_of
      ? prettifyNumber(+quantite_of)
      : quantite_of;
    const ofQuantityUnit = getOFUnit(operation);
    const ofQuantity = `${ofQuantityValue} ${ofQuantityUnit}`;
    const opQuantityTxt = `(${opQuantity})`;

    const fallbackText = minifiedDisplay
      ? [ofQuantity, `(${opQuantityValue} ${opQuantityUnit.charAt(0)})`]
      : [ofQuantity, opQuantityTxt];

    if (!includeOpQty) fallbackText.splice(1, 1);

    return {
      unite: opQuantityUnit,
      unite_of: ofQuantityUnit,
      opQuantityTxt,
      txt:
        quantite_of == null
          ? opQuantity
          : ofQuantity === opQuantity
          ? ofQuantity
          : fallbackText.join(" "),
    };
  }

  /**
   * returns fields & values used for display inside OperationsActionsMenu
   */
  function getSelectedOperationDetail(operation: SchedulingOperation) {
    return {
      quantite_of: operation.quantite_of,
      quantite_op: getOperationQuantity(
        operation,
        clientParameters.value.has_gantt_load_adjustment,
      ),
      unite_of: getOFUnit(operation),
      unite_op: operationComputedOpQuantityUnit(operation),
    };
  }

  return {
    operationComputedOpQuantityUnit,
    operationComputedQuantity,
    getSelectedOperationDetail,
  };
};

export default useComputeQuantityUnit;
