<template>
  <div
    :class="[
      wrapperClass,
      {'fd-flex-center gap-4': inlineSectors},
      {'tags-input__disabled': disabled},
    ]"
    :style="{'max-height': maxHeight}"
    class="d-flex overflow-y-auto keep-scrollbar tags-input min-height-40 flex-1 align-center justify-space-between"
    @click="(e) => $emit('click', e)"
  >
    <div class="fd-flex-center f-tag-input__container">
      <vue-feather
        v-if="icon"
        class="button-icon flex-shrink-0 flex-grow-0 ml-2"
        tag="div"
        :type="icon"
      />

      <slot v-if="tags.length === 0 && $slots.placeholder" name="placeholder" />

      <template v-else-if="inlineSectors">
        <div
          class="f-tag-input__inline-sectors flex-1"
          ref="inlineSectorsContainerElement"
        >
          <FChip
            class="max-width-100 mr-2"
            v-for="(tag, index) in computedTags"
            :key="tag"
            hide-caret
            color="newPrimaryRegular"
            active-color="newPurpleDark1"
            :id="`tag-${index}`"
            :input-id="`tag-input-${index}`"
            :prepend-icon="machineIcons && tag.is_machine ? 'tool' : ''"
            :close="!disabled"
            inert
            :data-testid="`tag-${tag[itemValue] || index}`"
            :class="{'visibility-hidden': index > visibleTags - 1}"
            @close="() => onChange(tag, {}, {}, true)"
          >
            {{ tag[itemText] }}
          </FChip>
        </div>
        <v-dialog v-model="open" width="50%" :z-index="zIndex">
          <template v-slot:activator="{props}">
            <div
              v-show="hiddenTags > 0"
              v-bind="props"
              class="show-all-sectors text-newDisableText cursor-pointer"
              data-testid="tag-input-show-more"
            >
              {{ `+ ${hiddenTags} ${$t("global.other", hiddenTags)}` }}
            </div>
          </template>
          <v-card class="pa-4">
            <v-card-text class="px-0">
              <div class="">
                <FDropdown
                  type="perimeter"
                  :default-path="defaultPath"
                  :forced-level="forcedLevel"
                  :z-index="zIndex"
                  outlined
                  toggle-default
                  keep-scrollbar
                  keep-open
                  :item-value="itemValue"
                  :item-text="itemText || 'text'"
                  wrapper-class="home-perimeters-select w-100 fd-flex-center"
                  tag-input
                  multi-select
                  :tags="tags"
                  data-testid="tag-input-dropdown"
                  :display-machine-tags="displayMachineTags"
                  :on-change="onChange"
                  :on-select-all="onSelectAll"
                />
              </div>
            </v-card-text>

            <v-card-actions class="px-0">
              <FButton
                textClass="text-newPrimaryRegular"
                data-testid="tag-input-reset"
                @click="onResetAll"
              >
                {{ $t("global.clear") }}
              </FButton>
              <v-spacer></v-spacer>
              <FButton
                class="ml-auto"
                data-testid="tag-input-validate"
                filled
                @click="onModalValidate"
              >
                {{ $t("global.valider") }}
              </FButton>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <div v-else class="max-width-100 flex-wrap">
        <template v-if="!hideSectorsGroups && selectedSectorsGroups.length > 0">
          <SectorGroupItem
            v-for="sectorGroup in selectedSectorsGroups"
            :key="sectorGroup.id"
            :sector-group="sectorGroup"
            display-prepend-icon
          />
        </template>

        <span
          v-for="tag in filteredComputedTags"
          class="max-width-100 mr-2"
          :key="tag[itemValue]"
        >
          <FChip
            :key="tag[itemValue]"
            input
            hide-caret
            v-model="tag[itemText]"
            color="newPrimaryRegular"
            active-color="newPurpleDark1"
            :id="`tag-${tag[itemValue]}`"
            :input-id="`tag-input-${tag[itemValue]}`"
            :prepend-icon="machineIcons && tag.is_machine ? 'tool' : ''"
            :close="!disabled"
            :inert="disabled"
            show-tooltip
            :data-testid="`tag-${tag[itemValue]}`"
            @close="() => onChange(tag, {}, {}, true)"
          />
        </span>
      </div>
    </div>

    <slot name="append-icon" />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  DefineComponent,
  PropType,
  computed,
  onMounted,
  watch,
  nextTick,
} from "vue";
import _ from "lodash";
import {useResizeObserver} from "@vueuse/core";
import FButton from "../Buttons/FButton.vue";
import FChip from "../Commons/FChip.vue";
import {FDropdownProps, Sector} from "@/interfaces";
import SectorGroupItem from "@/components/Commons/SectorGroupItem.vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "figma-tag-input",
  components: {
    FChip,
    FButton,
    FDropdown: defineAsyncComponent<DefineComponent<FDropdownProps>>(
      () => import("../Dropdowns/FDropdown.vue") as any,
    ),
    SectorGroupItem,
  },
  props: {
    tags: {type: Array, default: () => []},
    elementId: String,
    itemValue: {type: String, default: "key"},
    itemText: {type: String, default: "value"},
    wrapperClass: {type: String, default: ""},
    icon: {type: String, default: ""},
    useSimpleTags: Boolean,
    maxHeight: {type: String, default: "300px"},
    inlineSectors: {type: Boolean, default: false},
    machineIcons: {type: Boolean},
    onChange: {type: Function, default: () => null},
    forcedLevel: {type: Object, default: () => ({})},
    defaultPath: {type: Object, default: () => ({})},
    onSelectAll: {
      type: Function as PropType<
        (
          sector: Sector,
          level: {level: number},
          hasChildSelected?: boolean,
        ) => void
      >,
      default: () => void 0,
    },
    onResetAll: {type: Function as PropType<() => void>, default: () => void 0},
    zIndex: {type: Number, default: 10000},
    disabled: {type: Boolean, default: false},
    displayMachineTags: {type: Boolean, default: false},
    hideSectorsGroups: {type: Boolean, default: false},
  },
  emits: ["click", "on-confirm"],

  setup(props) {
    const {variables} = storeToRefs(useMainStore());

    const tag = ref({});
    const input = ref("");
    const hiddenInput = ref("");
    const open = ref(false);
    const inlineSectorsContainerElement = ref<HTMLElement>(null);
    const visibleTags = ref(0);

    const selectedSectorsGroups = computed(() =>
      computedTags.value.filter(({is_group}) => is_group),
    );
    const simplifiedTags = computed(() =>
      props.tags.filter(Boolean).map((tag: any) => ({
        ...tag,
        [props.itemText]: _.last((tag[props.itemText] + "").split(" > ")),
      })),
    );
    const computedTags = computed(() =>
      props.useSimpleTags ? simplifiedTags.value : props.tags,
    );
    const filteredComputedTags = computed(() => {
      if (props.hideSectorsGroups || !selectedSectorsGroups.value.length)
        return computedTags.value;

      return computedTags.value.filter((tag) => !tag.is_group);
    });
    const hiddenTags = computed(
      () => computedTags.value.length - visibleTags.value,
    );

    function determineVisibleTags() {
      if (!inlineSectorsContainerElement.value) return;
      const cumulativeWidths = [];
      const allTagsElements =
        inlineSectorsContainerElement.value.querySelectorAll<HTMLElement>(
          ":scope > span[data-testid]",
        );

      for (let i = 0; i < allTagsElements.length; i++) {
        const currentElement = allTagsElements[i];
        /** checking if accumulated widths of tags exceed the available space of the container */
        const currentTotalWidth = // 8 corresponds to margin-right value
          (cumulativeWidths[i - 1] || 0) + currentElement.offsetWidth + 8;

        if (
          currentTotalWidth > inlineSectorsContainerElement.value.offsetWidth
        ) {
          /**
           * when we shrink the container enough, we end up with the first chip being larger than the remaining space in the container
           * rather than only displaying the message of hidden tags, we keep the first chip shrinked and exclude it from the calculation
           */
          visibleTags.value = Math.max(1, i);
          break;
        } else if (i === allTagsElements.length - 1) {
          visibleTags.value = allTagsElements.length;
          break;
        } else cumulativeWidths.push(currentTotalWidth);
      }
    }
    const debouncedDetermineVisibleTags = _.debounce(determineVisibleTags, 100);

    watch(
      () => props.tags,
      async () => {
        if (!props.inlineSectors) return;
        await nextTick();
        determineVisibleTags();
      },
      {immediate: true},
    );

    onMounted(() => {
      if (!props.inlineSectors) return;
      useResizeObserver(
        inlineSectorsContainerElement,
        debouncedDetermineVisibleTags,
      );
    });

    return {
      tag,
      input,
      hiddenInput,
      open,
      variables,
      computedTags,
      selectedSectorsGroups,
      filteredComputedTags,
      visibleTags,
      inlineSectorsContainerElement,
      hiddenTags,
    };
  },
  methods: {
    onModalValidate() {
      this.$emit("on-confirm");
      this.open = false;
    },
  },
});
</script>

<style scoped lang="scss">
.tags-input__disabled {
  color: rgb(var(--v-theme-newDisableText)) !important;
  background-color: rgb(var(--v-theme-newDisableBG)) !important;
  border: 1px solid rgb(var(--v-theme-newSelected)) !important;
  cursor: default !important;

  &:hover {
    color: rgb(var(--v-theme-newDisableText)) !important;
    background-color: rgb(var(--v-theme-newDisableBG)) !important;
    border: 1px solid rgb(var(--v-theme-newSelected)) !important;
  }
}
.min-height-40 {
  min-height: 40px;
}
.show-all-sectors {
  font-style: italic;
  text-decoration-line: underline;
  flex: 0 0 auto;
}

.v-overlay-container .tags-input {
  padding: 8px;
}

.f-tag-input__inline-sectors {
  overflow: hidden;
  white-space: nowrap;
}

.f-tag-input__container {
  gap: inherit;
  width: calc(100% - 32px);
}
</style>
