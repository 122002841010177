<template>
  <div
    :class="[
      'right-sidebar flex-1 align-self-start',
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
    ]"
  >
    <div v-if="computedOp" class="bar-header">
      <div class="px-6 pt-6 text-14">
        <div class="d-flex align-center text-h6 gap-8">
          <div class="fd-flex-center gap-4">
            <span
              v-tooltip="cleanedOp.of_id"
              class="right-sidebar-of-name font-weight-bold text-ellipsis"
            >
              {{ cleanedOp.of_id }}
            </span>

            <vue-feather
              v-if="!!cleanedOp.parent_of_id"
              type="edit-2"
              size="20px"
              class="cursor-pointer"
              @click="() => (editSubOF = true)"
            />
          </div>
          <div class="fbody-1 align-self-center" data-testid="rightbar-tags">
            <OperationTags
              :operation="{tags}"
              :z-index="zIndex"
              hide-status
              :removable="!readOnly"
              :addable="!readOnly"
              @add="onSaveTags"
              @remove="removeTag"
            />
          </div>
          <div class="right-sidebar-buttons d-flex align-center ml-auto">
            <OperationPriorityChip
              v-if="!loading.of"
              :op="computedOp"
              :z-index="zIndex"
              :read-only="
                readOnly || !currentPermissions.scheduling.create_update_of_tags
              "
              @change-priority="savePriority"
            />
            <vue-feather
              class="cursor-pointer ml-2"
              type="x"
              size="20px"
              :data-testid="TEST_IDS.OPERATIONS_RIGHT_BAR__CLOSE_ICON"
              @click="closeSidebar"
            />
          </div>
        </div>
        <div class="fbody-1 mt-2" v-if="computedDisplay">
          {{ computedDisplay }}
        </div>
        <div
          class="fbody-1"
          v-if="computedOp.customer || computedOp.order_number != null"
        >
          {{ cleanedOp.customer }} | {{ cleanedOp.order_number }}
        </div>
        <div v-if="computedOfQuantity" class="fd-flex-center">
          <vue-feather class="mr-2" type="box" size="18px" />
          <span>
            {{ computedOfQuantity }}
          </span>
        </div>
        <div v-if="formattedOfDate" class="fd-flex-center flex-wrap">
          <vue-feather class="mr-2" type="calendar" size="18px" />
          <div>{{ operationRealDateLabel }}</div>
          <div
            :class="[
              'ml-1',
              isLate ? 'text-newPinkRegular' : 'text-newGreenRegular',
            ]"
            :data-testid="TEST_IDS.OPERATIONS_RIGHT_BAR__DELAY_TEXT"
          >
            {{ formattedOfDate }}
          </div>
        </div>
        <div v-if="formattedOfDate" class="fd-flex-center font-italic">
          <span>
            {{ $t("scheduling.scheduled-on") }}
            {{ computedEndDateFormatted }}, {{ $t("scheduling.erp-date") }}
            {{ formattedTheoricDate }}
          </span>
        </div>

        <SubOFWrapper
          v-if="clientParameters.can_create_sub_ofs && !readOnly"
          :of="ofWithOperations"
          :edit="editSubOF"
          @sub-of-created="onSubOFCreated"
          @sub-of-updated="onSubOFUpdated"
          @sub-of-deleted="onSubOFDeleted"
          @quantite-of-updated="onQuantiteOFUpdated"
          @close-modal="() => (editSubOF = false)"
        />
      </div>
      <v-tabs
        v-model="selectedTab"
        direction="horizontal"
        class="right-side-bar-body"
        slider-color="primary"
      >
        <div class="sidebar-menu-header d-flex">
          <v-tab
            v-for="tab in tabs"
            :key="tab.name"
            color="primary"
            selected-class="text-primary font-weight-bold"
            class="no-uppercase w-50"
            :class="{'w-100': tabs.length === 1}"
          >
            <span
              v-if="tab.icon === 'message-circle'"
              class="message-feather mr-2"
            >
              <vue-feather
                :type="tab.icon"
                :class="{'has-messages': messages.length > 0}"
                size="16px"
              />
            </span>
            <vue-feather v-else class="mr-2" :type="tab.icon" size="20px" />
            {{ $t(tab.name) }}
          </v-tab>
        </div>
      </v-tabs>
    </div>
    <v-window
      v-model="selectedTab"
      class="tabs-wrapper keep-scrollbar"
      :key="`operations-right-bar-${selectedTab}`"
      ref="operationsRightBarScrollContainer"
    >
      <v-window-item class="bg-newLayerBackground">
        <div
          v-for="alert in alertsToDisplay"
          :key="alert"
          class="fd-flex-center pa-4 fbody-2"
        >
          <vue-feather
            type="alert-triangle"
            tag="div"
            class="mr-2"
            size="16"
            stroke="red"
          />
          <span>{{ alert }}</span>
        </div>
        <div v-if="computedOp">
          <Flux
            :selected-op="computedOp"
            :loading="loading.of"
            :operations-list="pg_ops"
            :propagate-ops-changes="propagateOpsChanges"
            :z-index="zIndex"
            :read-only="readOnly"
          />
        </div>
      </v-window-item>
      <v-window-item
        class="bg-newLayerBackground d-flex flex-column flex-1"
        v-if="computedOp"
      >
        <CommentsChat :messages="messages" :op="computedOp" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import {
  defineComponent,
  toRefs,
  ref,
  inject,
  computed,
  PropType,
  unref,
} from "vue";
import {storeToRefs} from "pinia";
import moment from "moment";
import {
  usePGWatcher,
  usePGComputedProperties,
  usePGSubscriptions,
} from "@/composables/pgComposable";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {pgOpsMapFn} from "@/tscript/utils/schedulingUtils";
import OperationPriorityChip from "@/components/Scheduling/Operations/OperationPriorityChip.vue";
import OperationTags from "@/components/Scheduling/Operations/OperationTags.vue";
import {CommentsChat} from "./Echanges";
import {Flux} from "./Flux";
import {
  CSS_FLUX_SELECTED_OP_CLASS,
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  OF_STATUS,
} from "@/config/constants";
import {SchedulingOperation, OpenSnackbarFunction} from "@/interfaces";
import {
  type SchedulingTag,
  type SubOF,
  PARAMETERS_IMPORT_PARSING_RULES_CONCATENATION_EXPRESSION,
  getEstimatedEndDate,
  getReadableImportParsingRuleValue,
  type DailyLoadWithSimulationData,
  SchedulingMessage,
} from "@oplit/shared-module";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "@/stores/mainStore";
import {Unsubscribe} from "firebase/firestore";
import SubOFWrapper from "@/components/Scheduling/SubOF/SubOFWrapper.vue";
import {useInternationalizedFunctions} from "@/composables/i18n/useInternationalizedFunctions";
import {useI18n} from "vue-i18n";
import {TEST_IDS} from "@/config/constants";

export default defineComponent({
  name: "operations-right-bar",
  components: {
    Flux,
    CommentsChat,
    OperationTags,
    OperationPriorityChip,
    SubOFWrapper,
  },
  props: {
    op: {
      type: Object as PropType<SchedulingOperation>,
      default: () => ({} as SchedulingOperation),
    },
    closeSidebar: {type: Function, default: () => ({})},
    useAbsoluteOf: {type: Boolean, default: false},
    useDailyLoadConcatenated: {type: Boolean, default: false},
    readOnly: {type: Boolean, default: false},
    defaultSelectedTab: {type: Number, default: 0},
  },
  setup(props) {
    const {defaultSelectedTab} = toRefs(props);

    const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");
    const {t} = useI18n();

    const schedulingStore = useSchedulingStore();
    const {
      sendSchedulingLoadEventToBackend,
      updateMultipleSchedulingTagsFromIds,
    } = schedulingStore;
    const {
      selectedSimulation: simulation,
      pgOpsModifications,
      schedulingTags,
      shouldHandleOPDuration,
      messagesByOfId,
    } = storeToRefs(schedulingStore);
    const mainStore = useMainStore();
    const {
      userData,
      apiClient,
      isDevEnv,
      perimeters,
      variables,
      stations,
      clientParameters,
      pgRefresh,
    } = storeToRefs(mainStore);
    const {getCtxSectorOrderedCalendars} = mainStore;

    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {canUseSimulation} = usePGComputedProperties({
      simulation,
    });
    const {pg_init, pg_subscribe} = usePGSubscriptions({simulation});
    const {getOFUnit} = useInternationalizedFunctions();

    const ofSidebarZindex = inject("ofSidebarZindex", 2000);
    const zIndex = ref<number>(ofSidebarZindex + 1);
    const pg_ops = ref<Partial<DailyLoadWithSimulationData>[]>([]);
    const rawPgOps = ref<unknown[]>([]);
    const loading = ref<Record<string, boolean>>({
      of: false,
    });
    const ofSubOFs = ref<SubOF[]>([]);
    const ofSubOFsCount = ref(0);
    const editSubOF = ref(false);

    const computedOfId = computed(() => props.op.of_id);
    // find the OP within the OFs list with matching `op_id`
    const opFromOF = computed(() => {
      if (!pg_ops.value?.length) return;
      const matchedOpId = pg_ops.value.find((operation, idx) => {
        if (!props.op.op_id) return idx === 0;
        return props.op.op_id === operation.op_id;
      });
      if (matchedOpId) return matchedOpId;
      const matchedOpSequence = pg_ops.value.find((operation, idx) => {
        if (props.op.op_sequence == null) return idx === 0;
        return props.op.op_sequence == operation.op_sequence;
      });
      return matchedOpSequence;
    });

    const messages = computed<SchedulingMessage[]>(() => {
      if (!computedOfId.value) return [];
      return messagesByOfId.value[computedOfId.value] || [];
    });

    const firstAvailableOP = computed(() => {
      return pg_ops.value?.find(
        (operation) =>
          operation?.op_status !== OF_STATUS.DONE && !operation.status_done_at,
      );
    });

    const computedOp = computed(() => {
      /**
       * the `forceFirstAvailable` attribute has been added to force the default
       * selected operation within the flux to be the first one which status isn't OF_STATUS.DONE
       * this is the case for the OFs table view that doesn't have directly its operations listed
       */
      const {op} = props;
      const {forceFirstAvailable = false} = op ?? {};

      const returnedOP = opFromOF.value ?? op;

      if (!forceFirstAvailable) return returnedOP;

      return firstAvailableOP.value ?? returnedOP;
    });

    const lastOP = computed(() => {
      return pg_ops.value?.at(-1) ?? computedOp.value;
    });

    const cleanedOp = computed(() =>
      _.mapValues(unref(computedOp), getReadableImportParsingRuleValue),
    );

    const concatenationExpression =
      PARAMETERS_IMPORT_PARSING_RULES_CONCATENATION_EXPRESSION;
    const computedDisplay = computed(() => {
      const {op} = props;
      return op.ref_article?.replaceAll(concatenationExpression, " - ");
    });

    const alertsToDisplay = computed(() => {
      const {client_id} = userData.value || {};
      const {id: simulation_id} = simulation.value || {};

      const alerts = [];

      if (loading.value.of) return alerts;

      if (!client_id) alerts.push("Le client n'est pas correctement défini");
      if (
        !simulation_id &&
        !props.useAbsoluteOf &&
        !props.useDailyLoadConcatenated
      )
        alerts.push("La simulation n'est pas correctement définie");
      if (!computedOfId.value)
        alerts.push("Le numéro d'OF n'est pas correctement défini");
      if (!pg_ops.value?.length)
        alerts.push("La liste des OPs n'a pas pu être récupérée");
      if (!computedOp.value) {
        alerts.push(
          "Les opérations de l'OF n'ont pas pu être correctement reconstruites",
        );
      }
      return alerts;
    });

    const computedOpSector = computed(() => {
      const {secteur_id, initial_secteur_id} = computedOp.value as {
        secteur_id: string;
        initial_secteur_id?: string;
      };
      const coalescedSecteurId = initial_secteur_id ?? secteur_id;
      return stations.value.find((s) => s.id === coalescedSecteurId);
    });

    const ofWithOperations = computed(() => ({
      ...computedOp.value,
      operations: pg_ops.value || [],
      sub_ofs: ofSubOFs.value,
      sub_ofs_count: ofSubOFsCount.value,
    }));

    function getMappedOperations(
      operationsList: unknown[],
      shouldGroupBySequence: boolean,
    ) {
      return pgOpsMapFn(operationsList, {
        of_ordering: true,
        keepOpsDone: true,
        removeSmoothedDuplicates: true,
        groupBySequence: shouldGroupBySequence,
        keepExtraInfos: true,
      });
    }

    function propagateOpsChanges(
      updatesList: (Record<string, unknown> & {op_id?: string})[],
    ) {
      if (props.readOnly) return;

      /**
       * we use `rawPgOps` to perform the update on the full list of operations
       */
      const mappedRawPgOps = getMappedOperations(rawPgOps.value, false);
      const tempPgOps = mappedRawPgOps.map((op: {op_id: string}) => {
        const matchOnNewUpdates = updatesList.find(
          ({op_id}) => op_id === op.op_id,
        );
        if (matchOnNewUpdates) return matchOnNewUpdates;
        else return op;
      });
      pg_ops.value = getMappedOperations(tempPgOps, true);
      pgOpsModifications.value = tempPgOps.filter((op) =>
        updatesList.some(({op_id}) => op_id === op.op_id),
      ); //we propagate only the changed OPs
    }

    function onSubOFCreated(subOF: SubOF) {
      ofSubOFs.value.push(subOF);
      ofSubOFsCount.value += 1;

      pgRefresh.value += 1;
    }

    function onSubOFUpdated({of_id, quantite_of}: SubOF) {
      ofSubOFs.value = ofSubOFs.value.map((subOF) => {
        if (subOF.of_id === of_id) {
          return {
            ...subOF,
            quantite_of,
          };
        }
        return subOF;
      });

      pgRefresh.value += 1;

      loadChargeOf();
    }

    function onSubOFDeleted(subOFID: SubOF["of_id"]) {
      ofSubOFs.value = ofSubOFs.value.filter(({of_id}) => of_id !== subOFID);

      pgRefresh.value += 1;

      props.closeSidebar();
    }

    function onQuantiteOFUpdated(newQuantiteOF: number) {
      if (!opFromOF.value) return;
      propagateOpsChanges([{...opFromOF.value, quantite_of: newQuantiteOF}]);
    }

    async function loadSubOFs() {
      const [, result] = await apiClient.value.getSubOFs({
        simulation_id: simulation.value.id || "",
        of_id: props.op.of_id,
      });

      if (!result) {
        openSnackbar({
          type: "negative",
          message: t("OperationsRightBar.load_sub_ofs_error"),
        });
        return;
      }

      const {sub_ofs, sub_ofs_count} = result;

      ofSubOFs.value = sub_ofs || [];
      ofSubOFsCount.value = sub_ofs_count;
    }

    async function loadChargeOf() {
      const {client_id} = userData.value || {};

      if (!client_id) return;
      if (!computedOfId.value) return;
      if (
        !simulation.value?.id &&
        !props.useAbsoluteOf &&
        !props.useDailyLoadConcatenated
      )
        return;

      let results: SchedulingOperation[];

      loading.value.of = true;

      if (props.useDailyLoadConcatenated) {
        [results] = await apiClient.value.getOPsByOF({
          ref_article: props.op.ref_article,
          of_id: computedOfId.value,
        });
      } else {
        const simulation_id = props.useAbsoluteOf ? "" : simulation.value?.id;

        results = await apiClient.value.ofsOpsFn(
          simulation_id,
          computedOfId.value,
          {
            prepare_sidebar_ops: true,
          },
        );
      }

      /**
       * we store the raw results so that we are able to perform modifications on the whole operations list
       * rather than the sequence-grouped one (due to the `groupBySequence` flag in `pgOpsMapFn` below)
       */
      rawPgOps.value = results;
      const operations = getMappedOperations(results, true);
      pg_ops.value = operations;
      loading.value.of = false;
    }

    const loadChargeDebounced = _.debounce(loadChargeOf, 60000);

    return {
      fastTrack: ref<boolean>(false), // a supprimer a l'integration du of fast track
      selectedTab: ref<number>(defaultSelectedTab.value),
      loading,
      messages,
      pg_ops,
      of_end_date: ref<string>(""),
      theoric_date: ref<string>(""),
      newTag: ref<string>(""),
      tags: ref<string[]>([]),
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      unsubscribeFn: ref<(...args: any[]) => Promise<Unsubscribe>>(null),
      canUseSimulation,
      pgOpsModifications,
      currentPermissions,
      pg_init,
      pg_subscribe,
      simulation,
      userData,
      apiClient,
      isDevEnv,
      perimeters,
      variables,
      zIndex,
      computedOfId,
      computedOp,
      firstAvailableOP,
      lastOP,
      cleanedOp,
      alertsToDisplay,
      computedOpSector,
      rawPgOps,
      getMappedOperations,
      shouldHandleOPDuration,
      sendSchedulingLoadEventToBackend,
      getCtxSectorOrderedCalendars,
      propagateOpsChanges,
      computedDisplay,
      clientParameters,
      ofWithOperations,
      onSubOFCreated,
      ofSubOFs,
      loadSubOFs,
      schedulingTags,
      updateMultipleSchedulingTagsFromIds,
      getOFUnit,
      onSubOFDeleted,
      onQuantiteOFUpdated,
      onSubOFUpdated,
      editSubOF,
      loadChargeOf,
      loadChargeDebounced,
      TEST_IDS,
    };
  },
  computed: {
    tabs(): {name: string; icon: string}[] {
      const tabs = [{name: "scheduling.flux", icon: "clipboard"}];
      if (!this.currentPermissions.general.create_comment || this.readOnly)
        return tabs;
      return [...tabs, {name: "scheduling.comments", icon: "message-circle"}];
    },
    formattedOfDate() {
      const {
        theoric_date,
        operationRealDate: of_end_date,
        computeOfEstimations,
      } = this;
      if (!of_end_date || !theoric_date) return "";
      const {real_of_delta} = computeOfEstimations;
      const formattedTheoricDate = moment(theoric_date).format("YYYY-MM-DD");

      const diffDays = moment(of_end_date).diff(formattedTheoricDate, "days");

      const coalescedDiffDays = real_of_delta ?? diffDays;
      const formattedDiffDays =
        coalescedDiffDays > 0
          ? `+ ${coalescedDiffDays}`
          : coalescedDiffDays === 0
          ? coalescedDiffDays
          : `- ${coalescedDiffDays * -1}`;

      return `(${formattedDiffDays}${this.$t("scheduling.days_diff")})`;
    },
    isLate() {
      const {
        theoric_date,
        operationRealDate: of_end_date,
        computeOfEstimations,
      } = this;
      const {real_theoric_date} = computeOfEstimations;

      if (
        (!moment(real_theoric_date).isValid() &&
          !moment(of_end_date).isValid()) ||
        !moment(theoric_date).isValid()
      )
        return false;

      return (
        moment(real_theoric_date ?? of_end_date).format("YYYY-MM-DD") >
        moment(theoric_date).format("YYYY-MM-DD")
      );
    },
    computeOfEstimations() {
      const {theoric_date, lastOP} = this;
      const {
        day_date,
        is_day_date_modified,
        status_done_at,
        all_ops_done,
        nb_ops_done,
        of_delta,
        first_active_op_planned_date,
        first_active_op_theoric_date,
      } = lastOP || {};
      if (!theoric_date) return {};
      const real_theoric_date = getEstimatedEndDate(
        first_active_op_theoric_date,
        of_delta,
        {
          initial_date: theoric_date,
          planned_date: day_date,
          first_active_op_planned_date,
          first_active_op_theoric_date,
          is_day_date_modified,
          has_of_started: !!nb_ops_done,
          all_ops_done,
        },
      );
      const real_of_delta = moment(
        real_theoric_date || status_done_at || theoric_date,
      ).diff(theoric_date, "days");
      return {real_of_delta, real_theoric_date};
    },
    computedEndDate() {
      const {pg_ops} = this;
      if (!pg_ops?.length) return;
      const ofEndDate = _.maxBy(
        pg_ops,
        (o: SchedulingOperation) => o?.new_date ?? o?.day_date,
      );
      return ofEndDate?.new_date ?? ofEndDate?.day_date;
    },
    computedEndDateFormatted() {
      const {computedEndDate} = this;
      return moment(computedEndDate).format("DD/MM/YYYY");
    },
    computedImportId() {
      return this.op.import_id;
    },
    computedOfQuantity() {
      const {computedOp} = this;
      const {quantite_of} = computedOp;

      if (quantite_of == null) return "";
      return `${quantite_of} ${this.getOFUnit(computedOp)}`;
    },
    formattedTheoricDate() {
      const {theoric_date} = this;
      return theoric_date ? moment(theoric_date).format("DD/MM/YYYY") : "";
    },
    everyOpDone() {
      const {pg_ops} = this;
      if (!pg_ops?.length) return;
      return pg_ops.every(
        (op: any) => (op?.new_status || op.op_status) === "done",
      );
    },
    operationRealDate() {
      const {pg_ops, computedEndDate, computeOfEstimations} = this;
      if (!pg_ops?.length) return;
      const lastOp = pg_ops.at(-1);
      const {status_done_at} = lastOp || {};
      const {real_theoric_date} = computeOfEstimations;
      return real_theoric_date ?? status_done_at ?? computedEndDate;
    },
    formattedOperationRealDate() {
      const {operationRealDate} = this;
      return moment(operationRealDate).format("DD/MM/YYYY");
    },
    operationRealDateLabel() {
      const {everyOpDone, formattedOperationRealDate} = this;
      return (
        this.$t(`scheduling.${everyOpDone ? "end_on" : "end_estimated_on"}`) +
        " " +
        formattedOperationRealDate
      );
    },
  },
  watch: {
    computedOp: {
      immediate: true,
      deep: true,
      async handler(val: any, oldVal: any) {
        if (!val?.of_id) return;
        this.tags = val.tags ?? [];
        if (val.of_id === oldVal?.of_id) return;
        this.loadOfEndDate();
        await this.loadChargeOf();
      },
    },
    simulation() {
      this.loadOfEndDate();
      this.loadChargeOf();
    },
    userData(val: any, oldVal: any) {
      if (!val?.client_id || val?.client_id === oldVal?.client_id) return;
      this.loadChargeOf();
    },
  },
  created() {
    this.pg_subscriptions = _.debounce(this.pg_subscriptions, 150);
    this.loadChargeOf = _.debounce(this.loadChargeOf, 300);
    this.loadOfEndDate = _.debounce(this.loadOfEndDate, 300);

    usePGWatcher(this);

    this.loadSubOFs();
  },
  beforeUnmount() {
    if (this.unsubscribeFn) this.unsubscribeFn();
  },
  updated() {
    this.scrollToSelectedOperation();
  },
  methods: {
    pg_subscriptions() {
      if (!this.canUseSimulation) return;
      this.pg_init();
      // this.pg_ops = []; //this empties all the sidebar, removed for now
      this.pg_subscribe(["daily_load"], () => {
        this.loadChargeDebounced();
      });
    },
    onSaveTags(tags: SchedulingTag[]) {
      const tagsIDs = tags.map(({id}) => id);
      this.tags.push(...tagsIDs);
      this.saveTags("add", tagsIDs);
    },
    async loadOfEndDate() {
      const {
        userData,
        computedImportId,
        simulation,
        computedOp,
        useAbsoluteOf,
      } = this;
      const {max_date_of, of_id} = computedOp;
      if (max_date_of) return (this.theoric_date = max_date_of); //new parsing directly in daily_load
      if ((!simulation?.id && !useAbsoluteOf) || !of_id || !userData?.client_id)
        return;
      const simulation_id = useAbsoluteOf ? "" : simulation?.id;
      const import_id = computedImportId ?? simulation.import_id;
      const {of_end_date, theoric_date} = await this.apiClient.loadOfEndDateFn(
        simulation_id,
        import_id,
        of_id,
      );
      if (!of_end_date.length) this.of_end_date = "";
      else this.of_end_date = of_end_date[0].day_date_max;

      if (!theoric_date.length) this.theoric_date = "";
      else this.theoric_date = theoric_date[0].day_date_max;
    },
    async removeTag(tag: string) {
      this.tags = this.tags.filter((id) => id !== tag);
      await this.saveTags("remove", [tag]);
    },
    async saveTags(type: "add" | "remove", tagIdsToUpdate?: string[]) {
      const {tags, computedOp, shouldHandleOPDuration} = this;
      const update: any = {tags};

      if (type === "add" && tagIdsToUpdate?.length)
        this.updateMultipleSchedulingTagsFromIds(tagIdsToUpdate);

      try {
        //OPL-4456 : nouveau format des évènements d'ordo
        const {updated_values} = await this.sendSchedulingLoadEventToBackend({
          data: [{initial: computedOp, update}],
          should_update_all_of: true,
          should_return_updated_values: true,
          should_handle_op_duration: shouldHandleOPDuration,
        });
        const mappedValues = this.getMappedOperations(updated_values, false);
        //propagate the change
        this.propagateOpsChanges(mappedValues);
      } catch (e) {
        this.$openSnackbar(null, "GENERIC_ERROR");
      }
    },
    async savePriority(priority: string) {
      const {computedOp, shouldHandleOPDuration} = this;
      const update = {fast_track: priority};
      try {
        //OPL-4456 : nouveau format des évènements d'ordo
        const {updated_values} = await this.sendSchedulingLoadEventToBackend({
          data: [{initial: computedOp, update}],
          should_update_all_of: true,
          should_return_updated_values: true,
          should_handle_op_duration: shouldHandleOPDuration,
        });
        const mappedValues = this.getMappedOperations(updated_values, false);
        //propagate the change
        this.propagateOpsChanges(mappedValues);
      } catch (e) {
        this.$openSnackbar(null, "GENERIC_ERROR");
      }
    },
    // scrolls to the selected operation within the Flux so that it renders at top
    scrollToSelectedOperation: _.debounce(function (this: any) {
      const scrollContainer = this.$refs.operationsRightBarScrollContainer;
      const selectedOperation = scrollContainer?.$el?.querySelector(
        `.${CSS_FLUX_SELECTED_OP_CLASS} > div:first-child`,
      );
      if (selectedOperation) {
        scrollContainer.$el.scrollTo?.({
          top: selectedOperation.offsetTop,
          behavior: "smooth",
        });
      }
    }, 150),
  },
});
</script>
<style lang="scss">
.message-feather {
  position: relative;
}
.inline-block {
  display: inline-block;
}
.right-sidebar {
  border: 1px solid rgb(var(--v-theme-newSelected));
  border-radius: 8px;
  overflow: hidden;
  box-shadow: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  &-date-picker {
    padding: 0;
    color: rgb(var(--v-theme-newPinkRegular));
  }
  &-of-name {
    color: rgb(var(--v-theme-newMainText));
    max-width: 120px;
  }
  &-horizontal-line {
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 40px;
    background-color: rgb(var(--v-theme-newSubText));
  }
  .right-side-bar-body {
    flex: 1;
    overflow: scroll;
  }
}
.tabs-wrapper {
  overflow-y: scroll;
  height: 100%;
}
.sidebar-menu-header {
  width: 100%;
  background-color: rgb(var(--v-theme-newSubBackground));
  border-bottom: 1px solid rgb(var(--v-theme-newSelected));
}
.bar-header {
  background-color: rgb(var(--v-theme-newSubBackground));
  color: rgb(var(--v-theme-newSubText));

  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
.new-tag {
  padding: 0px;
  height: 18px;
}

:deep(.chip) {
  input {
    background-color: rgb(var(--v-theme-newPrimaryDark1));
    font-weight: bold;
    color: rgb(var(--v-theme-newPrimaryLight2)) !important;
    caret-color: rgb(var(--v-theme-newLayerBackground));
    &::placeholder {
      color: rgb(var(--v-theme-newPrimaryLight2)) !important;
    }
  }
}
</style>
